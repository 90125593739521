// Components
import {
  Carousel,
  Collections,
  ProductCard,
  WhyChooseUs,
} from "../../components";
import React, { useEffect, useRef } from "react";
// Styles
import { Box, Typography } from "@mui/material";
import { useCategories } from "../../hooks/useCategories";
import HomeSectionComponent from "../../components/HomeSection/Section";
import HomeCategoryView from "../../components/HomeSectionComponents/CategoryView";
import { useVendors } from "../../hooks/useVendors";
import Vendor from "@/types/vendor";
import VendorView from "../../components/VendorView";
import PartnersSection from "../../components/Partners/OurPartners";
import useProducts from "../../hooks/useProducts";
import { Category } from "@/types/product";
import MostPurchasedCategories from "../../components/HomeSectionComponents/MostPurchasedCategories";
import QuotationForm from "../../components/Quotation";
import GroupCategory from "../../components/HomeSectionComponents/GroupedCategory";
import CategoryHighlights from "../../components/HomeSectionComponents/CategoryHighlights";
import AdSection from "../../components/AdSection";

const Banner = () => {
  const bannerStyle = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "300px",
    background: "linear-gradient(to right, #ff416c, #ff4b2b)",
    color: "#fff",
    textAlign: "center",
    padding: "20px",
    fontSize: "24px",
    borderRadius: "8px",
    margin: "20px",
    boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
    overflow: "hidden",
    position: "relative", // Necessary for absolute positioning of the image
  };

  const imageStyle = {
    position: "absolute",
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    width: "100%",
    height: "100%",
    objectFit: "cover",
    opacity: 0.5, // Adjust the opacity as needed
  };

  return (
    <div style={bannerStyle}>
      {/* <img
        src="https://ke.jumia.is/cms/2023/BlackFriday/Live/OfficialStores/KE_BF23_Offical_Stores_1023_SIS_Tecno.jpg" // Replace with the actual image URL
        alt="Banner Image"
        style={imageStyle}
      /> */}
      <div>
        <Typography variant="h2">Discover the Latest Trends!</Typography>
        <Typography variant="body1">
          Explore our new collection and get up to 50% off on selected items.
        </Typography>
        <button
          style={{
            padding: "10px",
            fontSize: "18px",
            backgroundColor: "#fff",
            color: "#ff4b2b",
            border: "none",
            borderRadius: "4px",
            cursor: "pointer",
          }}
        >
          Shop Now
        </button>
      </div>
    </div>
  );
};
const CategoriesSection = () => {
  const { categories } = useCategories();
  return (
    <HomeSectionComponent
      data={categories.slice(0, 15)}
      title="Categories"
      seeAllUrl="/categories"
      renderComponent={(item) => <HomeCategoryView category={item} />}
    />
  );
};

const VendorSection = () => {
  const { vendors, status } = useVendors();
  return (
    <HomeSectionComponent
      data={vendors.slice(0, 15)}
      title="Vendors"
      seeAllUrl="/vendors"
      renderComponent={(item) => <VendorView vendor={item} />}
    />
  );
};

const ProductsSection = () => {
  const { products, productsStatus, loading } = useProducts();
  return (
    <HomeSectionComponent
      data={products.slice(0, 5)}
      title="Products"
      seeAllUrl="/products"
      renderComponent={(item) => <ProductCard product={item} />}
    />
  );
};

import { useState } from "react";
import urls from "../../constants/urls";
import axios from "axios";

import { Skeleton } from "@mui/material";
import CustomerAdSection from "../../components/CustomerAdSection";
const GroupedCategories = () => {
  const [groupedCategories, setGroupedCategories] = useState([]);

  useEffect(() => {
    const fetchGroupedCategories = async () => {
      try {
        const response = await axios.get(
          urls.items.highlight_categories_with_products
        ); // Replace with your backend API endpoint

        setGroupedCategories(response.data);
      } catch (error) {
        console.error("Error fetching grouped categories:", error);
      }
    };

    fetchGroupedCategories();
  }, []);

  return (
    <div>
      {groupedCategories.length === 0 ? (
        <div className="grid grid-cols-1 gap-4">
          {Array.from({ length: 4 }).map((_, index) => (
            <div key={index} className="p-4 bg-white rounded shadow">
              <Skeleton variant="rectangular" width="100%" height={200} />
              <Skeleton variant="text" width="80%" height={30} />
              <Skeleton variant="text" width="60%" height={20} />
            </div>
          ))}
        </div>
      ) : (
        groupedCategories.map((category, index) => (
          <GroupCategory
            index={index}
            showAd={index % 2 !== 0}
            key={category.id}
            category={category.category}
            products={category.products}
          />
        ))
      )}
    </div>
  );
};
const Home = () => {
  return (
    <main>
      <Box>
        <Carousel />
        <Box
          sx={{
            padding: "0 2rem",
          }}
        >
          <WhyChooseUs />
          {/* <CategoryHighlights /> */}
          <MostPurchasedCategories />
          <CustomerAdSection adIndex={1} />

          <QuotationForm />
          <GroupedCategories />

          <AdSection
            items={[
              {
                image:
                  "https://manpro.co.ke/wp-content/uploads/2022/11/PNG-Website-Logo.png",
                title: "Manage your project with ManPro",
                link: "/about-us#manpro",
              },
              {
                title: "Secure payments with Paybills.Africa",
                image: "https://www.brij.africa/images/paybills.png",
                link: "/about-us#paybills.africa",
              },
            ]}
          />
          <PartnersSection />
        </Box>
      </Box>
    </main>
  );
};

export default Home;
