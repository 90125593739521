import React from "react";
interface AdItemProps {
  title: string;
  image: string;
  link: string;
}
interface AdSectionProps {
  items: Array<AdItemProps>;
}

const AdItem = ({
  title,
  image,
  link,
}: {
  title: string;
  image: string;
  link: string;
}) => {
  return (
    <a className="w-full md:w-[45%] h-[300px]" href={link} target="_blank">
      <div
        className="w-full h-full bg-cover bg-center items-center flex justify-center relative"
        style={{ backgroundImage: `url(${image})` }}
      >
        <div className="absolute bg-black bg-opacity-50 w-full h-full"></div>
        <h2 className="text-white text-xl  text-center  md:text-2xl relative z-10 lg:text-3xl font-bold">
          {title}
        </h2>
      </div>
    </a>
  );
};
const AdSection: React.FC<AdSectionProps> = ({ items }) => {
  return (
    <div className="flex  flex-wrap md:flex-nowrap md:space-x-2 space-y-2 items-center w-full my-20 justify-between ">
      {items.map((item) => {
        return <AdItem {...item} key={item.image} />;
      })}
    </div>
  );
};

export default AdSection;
