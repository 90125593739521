import axios from "axios";
import storagekeys from "../constants/storagekeys";
import urls from "../constants/urls";

export const checkIfProductExist = (
  productId: string,
  userProductsInCart: IUserProduct[]
) => {
  const isProductExist = userProductsInCart.some(
    (product) => product.id === productId
  );

  return isProductExist;
};

export const addUserProductsInCart = async ({
  product,
  quantity,
}: {
  product: number;
  quantity: number;
}) => {
  try {
    const token = JSON.parse(localStorage.getItem(storagekeys.user)!).token;
    const response = await axios.post(
      urls.cart.add_cart_items,
      {
        products: [
          {
            product: product,
            quantity: quantity,
          },
        ],
      },
      {
        headers: {
          Authorization: `Token ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

//Update quantity of product in cart
export const updateUserProductQuantityInCart = async ({
  product,
  quantity,
}: {
  product: number;
  quantity: number;
}) => {
  try {
    const token = JSON.parse(localStorage.getItem(storagekeys.user)!).token;
    const response = await axios.post(
      urls.cart.update_cart_item_quantity, // Consider changing `post` to `put` if your API uses PUT for updating resources
      {
        product: product,
        quantity: quantity,
      },
      {
        headers: {
          Authorization: `Token ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

//Remove product from cart
export const removeUserProductFromCart = async ({
  product,
}: {
  product: number;
}) => {
  try {
    const token = JSON.parse(localStorage.getItem(storagekeys.user)!).token;
    const response = await axios.delete(urls.cart.remove_cart_item(product), {
      headers: {
        Authorization: `Token ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.log(error);
  }
};
