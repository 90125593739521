import { CloseOutlined } from "@mui/icons-material";
import { Alert, IconButton } from "@mui/material";
import React, { useState } from "react";

const FlexpayIntegration = ({
  product,
  onClose,
}: {
  product: {
    id: number;
    name: string;
    price: number;
    description: string;
    image: string;
  };
  onClose: () => void;
}) => {
  const [bookingData, setBookingData] = useState({});
  const [bookingStatus, setBookingStatus] = useState("");
  const [status, setStatus] = useState<
    "error" | "idle" | "loading" | "success"
  >("idle");
  const [error, setError] = useState("");

  const verifyFields = (formData) => {
    const requiredFields = [
      "firstName",
      "lastName",
      "phoneNumber",
      "productDeposit",
      "bookingDays",
      "paymentType",
      "email",
    ];

    const missingFields = requiredFields.filter(
      (field) => !formData.has(field)
    );

    if (missingFields.length) {
      alert(`Missing fields: ${missingFields.join(", ")}`);
    }

    // verify number , email and payment type
  };

  const handleBooking = async (event) => {
    event.preventDefault();
    setStatus("loading");

    const formData = new FormData(event.target);
    formData.append("apiKey", "91ebe6bd-78c4-36fc-941f-72c069e52853");
    formData.append("apiSecret", "TXR0eUNRRGJ6WTF3TEVsSndO");
    formData.append("productName", product.name);
    formData.append("productPrice", product.price.toString());
    formData.append("paymentType", "MPESA");

    try {
      const response = await fetch(
        "https://www.flexpay.co.ke/3Api/api/v1/book/flexpay/endpoint",
        {
          method: "POST",
          body: formData,
        }
      );

      const data = await response.json();

      if (response.ok) {
        setStatus("success");
        setBookingData(data.booking);
        setBookingStatus(data.data.message);
        console.log(data.data.message);
      } else {
        setStatus("error");
        setBookingStatus(`Error: ${data.data.message}`);
        setError(data.data.message);
      }
    } catch (error: any) {
      setStatus("error");
      setBookingStatus(`Error: ${error.message}`);
      setError(error.message);
    }
  };

  return (
    <div className="max-w-4xl overflow-y-scroll  relative mx-auto mt-2 p-2 max-h-screen bg-gray-100 rounded-lg shadow-md items-center justify-center">
      <div
        onClick={onClose}
        className="absolute top-10 right-0 bg-black bg-opacity-50"
      >
        {/* close icon */}
        <IconButton
          sx={{
            position: "absolute",
            top: "10px",
            right: "10px",
            color: "#000",
          }}
          onClick={onClose}
        >
          <CloseOutlined />
        </IconButton>
      </div>
      <div className="flex flex-row items-center border-b-2 mb-2">
        <img
          src={"/flexpaylogo.webp"}
          alt={"Flexpay Logo"}
          className="w-20 h-20 object-contain"
        />
        <h2 className="text-xl font-bold ml-10             ">
          {" "}
          Flexpay (Lipa pole pole)
        </h2>
      </div>
      {/* product details */}
      <div className="flex flex-row items-center border-b-2 mb-2">
        <img
          src={product.image}
          alt={product.name}
          className="w-20 h-20 object-contain"
        />
        <div className="ml-10">
          <h2 className="text-base font-bold">{product.name}</h2>
          <p className="text-sm font-bold">Ksh {product.price}</p>
        </div>
      </div>
      {status == "loading" ? (
        <div className="flex items-center justify-center min-h-[40vh]">
          <div className="animate-spin rounded-full h-32 w-32 border-b-2 border-gray-900"></div>
          <span className="sr-only">Loading...</span>
          <p className="text-center">Booking in progress...</p>
        </div>
      ) : status === "success" ? (
        <Alert severity="success">
          Booking successful! Booking Reference: {bookingData.booking_reference}
        </Alert>
      ) : status == "error" ? (
        <Alert severity="error">{error}</Alert>
      ) : (
        <form
          onSubmit={handleBooking}
          className="grid grid-cols-1 md:grid-cols-2 gap-4"
        >
          {/* First Name */}
          <div>
            <label htmlFor="firstName" className="block text-sm font-bold mb-2">
              First Name:
            </label>
            <input
              type="text"
              id="firstName"
              name="firstName"
              className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring focus:border-blue-500"
            />
          </div>
          {/* Last Name */}
          <div className="md:col-span-1 md:col-start-1">
            <label htmlFor="lastName" className="block text-sm font-bold mb-2">
              Last Name:
            </label>
            <input
              type="text"
              id="lastName"
              name="lastName"
              className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring focus:border-blue-500"
            />
          </div>
          {/* Phone Number */}
          <div>
            <label
              htmlFor="phoneNumber"
              className="block text-sm font-bold mb-2"
            >
              Phone Number:
            </label>
            <input
              type="tel"
              id="phoneNumber"
              name="phoneNumber"
              required
              className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring focus:border-blue-500"
            />
          </div>
          {/* Product Deposit */}
          <div>
            <label
              htmlFor="productDeposit"
              className="block text-sm font-bold mb-2"
            >
              Deposit:
            </label>
            <input
              type="number"
              id="productDeposit"
              name="productDeposit"
              required
              className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring focus:border-blue-500"
            />
          </div>
          {/* Booking Days */}
          <div>
            <label
              htmlFor="bookingDays"
              className="block text-sm font-bold mb-2"
            >
              Booking Days(1-90 days):
            </label>
            <input
              type="number"
              id="bookingDays"
              name="bookingDays"
              required
              className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring focus:border-blue-500"
            />
          </div>

          {/* Email */}
          <div>
            <label htmlFor="email" className="block text-sm font-bold mb-2">
              Email:
            </label>
            <input
              type="email"
              id="email"
              name="email"
              className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring focus:border-blue-500"
            />
          </div>

          <button
            type="submit"
            className={`col-span-2 w-full md:w-auto bg-blue-500 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline `}
          >
            {"Book Product"}
          </button>
        </form>
      )}
    </div>
  );
};

export default FlexpayIntegration;
