import axios from "axios";
import React, { useEffect, useState } from "react";
import urls from "../../constants/urls";
import { Loading } from "..";
import { set } from "lodash";
import { CircularProgress } from "@mui/material";

interface IProps {
  user: any;
  paymentInitiateStatus: "success" | "failed" | "idle" | "intiating";

  setPaymentInitiateStatus: (
    value: "success" | "failed" | "idle" | "intiating"
  ) => void;
  createOrderStatus: "success" | "failed" | "loading" | "idle";

  setCreateOrderStatus: (
    value: "success" | "failed" | "loading" | "idle"
  ) => void;
  paymentCompletionStatus: "pending" | "completed" | "failed";
  setPaymentCompletionStatus: (
    value: "pending" | "completed" | "failed"
  ) => void;

  orderGroup: any;
  setOrderGroup: (value: any) => void;
  paymentModalVisible: boolean;
  setPaymentModalVisible: (value: boolean) => void;
}

export const PaymentModal = ({
  user,
  paymentInitiateStatus,
  setPaymentInitiateStatus,
  createOrderStatus,
  setCreateOrderStatus,
  setPaymentCompletionStatus,
  paymentCompletionStatus,
  orderGroup,
  setOrderGroup,
  paymentModalVisible,
  setPaymentModalVisible,
}: IProps) => {
  //   console.log(user);
  const [timerId, setTimerId] = useState(null);
  const [mpesaNumber, setMpesaNumber] = useState("");
  const [currentStep, setCurrentStep] = useState<"order" | "payment">("order");
  const createOrders = async () => {
    setCreateOrderStatus("loading");
    try {
      const resp = await axios.post(
        urls.orders.create_cart_orders,
        {
          address: localStorage.getItem("addressData"),
        },
        {
          headers: {
            Authorization: `Token ${user?.token}`,
          },
        }
      );

      if (resp.status === 201 || resp.status === 200) {
        setCreateOrderStatus("success");
        setOrderGroup(resp.data);
        setCurrentStep("payment");
      } else {
        setCreateOrderStatus("failed");
      }
    } catch (error) {
      setCreateOrderStatus("failed");
      console.log("Error creating order", error);
    }
  };
  const sendPaymentPrompt = async () => {
    setPaymentInitiateStatus("intiating");
    try {
      const resp = await axios.post(
        urls.orders.order_stk_push,
        {
          order_group: orderGroup.id,
          phone_number: mpesaNumber,
        },
        {
          headers: {
            Authorization: `Token ${user?.token}`,
          },
        }
      );

      if (resp.status === 200 || resp.status === 201) {
        setPaymentInitiateStatus("success");
      } else {
        setPaymentInitiateStatus("failed");
      }
    } catch (error) {
      setPaymentInitiateStatus("failed");
    }
  };

  const checkPaymentStatus = async () => {
    if (paymentCompletionStatus === "completed") return;
    if (paymentCompletionStatus === "failed") {
      return;
    }
    try {
      const resp = await axios.get(
        `${urls.orders.check_order_payment_status}?order_group_id=${orderGroup.id}`,
        {
          headers: {
            Authorization: `Token ${user?.token}`,
          },
        }
      );

      if (resp.status === 200) {
        if (resp.data["order_payment_status"] === "completed") {
          setPaymentCompletionStatus("completed");
          return;
        }
        if (resp.data["order_payment_status"] === "failed") {
          setPaymentCompletionStatus("failed");
          return;
        }
        setPaymentCompletionStatus("pending");
      }
    } catch (error) {
      setPaymentCompletionStatus("pending");
    }
  };

  //   console.log(orderGroup);
  return (
    <div className="z-30 bg-black bg-opacity-50 w-screen h-screen fixed top-0 left-0 items-center justify-center">
      <div className="bg-white rounded-xl px-10 py-10 items-center justify-center flex flex-col ">
        {/* Order Confirmation */}
        {currentStep === "order" ? (
          <>
            <h2
              className="text-center text-2xl font-bold"
              style={{ color: "#1A1A1A" }}
            >
              Confirm Your Order
            </h2>{" "}
            <div className="flex flex-col items-center justify-center">
              {createOrderStatus === "idle" ? (
                <>
                  <p>Are you sure you want to place this order?</p>
                  <button
                    className="bg-black text-white rounded-md px-4 py-2 mt-4"
                    onClick={createOrders}
                  >
                    Confirm Order
                  </button>
                </>
              ) : createOrderStatus === "loading" ? (
                <div>
                  <Loading />
                  <p>Creating your order...</p>
                </div>
              ) : createOrderStatus === "success" ? (
                <>
                  <p>Order successfully created with ID {orderGroup.id}</p>
                  <button
                    className="bg-black text-white rounded-md px-4 py-2 mt-4 hover:bg-gray-800"
                    onClick={() => setCurrentStep("payment")}
                  >
                    Proceed to payment
                  </button>
                </>
              ) : (
                <>
                  <p>Order creation failed. Please try again.</p>
                  <button
                    className="bg-black text-white rounded-md px-4 py-2 mt-4 hover:bg-gray-800"
                    onClick={createOrders}
                  >
                    Confirm Order
                  </button>
                </>
              )}
            </div>
          </>
        ) : (
          <>
            {/* Payment Status checking */}
            {paymentInitiateStatus == "idle" ? (
              <div className="flex flex-col items-center justify-center">
                <p>Enter your M-Pesa number to complete payment</p>
                <input
                  type="text"
                  name="phone_number"
                  placeholder="Enter M-Pesa Number"
                  className="border-2 border-black rounded-md px-4 py-2 mt-4 w-1/2 text-center"
                  value={mpesaNumber}
                  onChange={(e) => setMpesaNumber(e.target.value)}
                />
                <button
                  className="bg-black text-white rounded-md px-4 py-2 mt-4"
                  onClick={sendPaymentPrompt}
                >
                  Make Payment
                </button>
              </div>
            ) : paymentInitiateStatus === "intiating" ? (
              <p>Intiating your payment...</p>
            ) : paymentInitiateStatus === "success" ? (
              <div className="flex flex-col items-center justify-center">
                <p>
                  Payment initiated successfully. Please complete the payment on
                  your device.
                </p>
                {/* Payment Completion Status */}
                {paymentCompletionStatus === "completed" ? (
                  <p>Payment completed successfully.</p>
                ) : paymentCompletionStatus === "failed" ? (
                  <p>Payment failed. Please try again.</p>
                ) : paymentCompletionStatus === "pending" ? (
                  <div className="flex flex-col items-center justify-center">
                    <CircularProgress />
                    <p>Waiting for payment completion...</p>
                    <button
                      className="bg-black text-white rounded-md px-4 py-2 mt-4"
                      onClick={checkPaymentStatus}
                    >
                      I have completed payment
                    </button>
                    <button
                      className="bg-black text-white rounded-md px-4 py-2 mt-4"
                      onClick={sendPaymentPrompt}
                    >
                      Resend Payment Prompt
                    </button>
                  </div>
                ) : null}
              </div>
            ) : paymentInitiateStatus === "failed" ? (
              <div className="flex flex-col items-center justify-center">
                <p>Failed to initiate payment. Please try again.</p>
                <button
                  className="bg-black text-white rounded-md px-4 py-2 mt-4"
                  onClick={sendPaymentPrompt}
                >
                  Retry
                </button>
              </div>
            ) : null}
          </>
        )}
      </div>
    </div>
  );
};
