import { useEffect, useState } from "react";
import axios from "axios";
import urls from "../constants/urls";
import { useAppSelector } from "../app/store";
import Vendor from "../types/vendor";

const useVendorDetail = (slug: string | undefined) => {
  const [vendor, setVendor] = useState<Vendor | null>(null);
  const [status, setStatus] = useState<
    "idle" | "loading" | "success" | "error"
  >("idle");

  const user = useAppSelector((state) => state.user);

  const fetchVendorDetail = async () => {
    setStatus("loading");
    try {
      const response = await axios.get(`${urls.vendors.detail(slug)}`, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      setVendor(response.data);
      setStatus("success");
    } catch (error) {
      console.error(error);
      setStatus("error");
    }
  };
  console.log(slug);
  useEffect(() => {
    if (slug) {
      fetchVendorDetail();
    }
  }, [slug]); // Re-fetch when vendorId changes

  return { vendor, status, fetchVendorDetail };
};

export default useVendorDetail;
