import React from "react";
import {
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Typography,
  Grid,
  Button,
} from "@mui/material";
import { Link } from "react-router-dom";
import { Category, IProduct } from "../@/types/product";
import CompactProductCard from "../../ProductCard/compact";
import CustomerAdSection from "../../CustomerAdSection";

const GroupCategory = ({
  category,
  products,
  showAd,
  index,
}: {
  category: Category;
  products: IProduct[];
  showAd?: boolean;
  index: number;
}) => {
  return (
    <div className="container mx-auto my-8  w-full overflow-hidden  border-b-2 border-b-gray-300 pb-5">
      <h2 className="text-2xl font-semibold mb-4">
        {category.name}
        <span className="text-red-600">.</span>
      </h2>
      <div className="grid grid-cols-1 md:grid-cols-6 gap-4">
        {products.map((product, index) => (
          <div className="col-span-1 md:col-span-1" key={index}>
            <CompactProductCard
              shadow={false}
              showFavoriteButton={false}
              product={product}
            />
          </div>
        ))}
      </div>
      {showAd ? (
        <div className="flex flex-col w-full items-center justify-center m">
          <div className="w-full md:w-[80vw] md:px-0 grid grid-cols-1 md:grid-cols-2 md:gap-3 ">
            {index == 1 ? (
              <>
                <CustomerAdSection adIndex={2} />
                <CustomerAdSection adIndex={3} />
              </>
            ) : (
              <>
                <CustomerAdSection adIndex={4} />
                <CustomerAdSection adIndex={5} />
              </>
            )}
          </div>
        </div>
      ) : null}
    </div>
  );
};
export default GroupCategory;
