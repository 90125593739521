const ResourceCenter = () => {
  const resources = [

    {
      name: "County Council",
      link: "https://www.nairobi.go.ke/",
    },
    {
      name: "NEMA",
      link: "https://www.nema.go.ke/",
    },
  ];
  return (
    <div className="min-h-screen bg-white  items-start mx-auto pl-20 flex flex-col">
      <h1 className="text-4xl font-bold text-red-600 mb-8">Resource Center</h1>
      <div className=" flex items-center justify-center">
        <div className="grid grid-cols-2 gap-4">
          <div className="flex flex-col justify-center items-start space-y-4">
            {resources.map((resource, index) => (
              <a
                href={resource.link}
                target="_blank"
                rel="noreferrer"
                key={index}
              >
                <div className="flex items-center space-x-4">
                  <div className="w-8 h-8 bg-red-600 rounded-full flex items-center justify-center">
                    <span className="text-white">{index + 1}</span>
                  </div>
                  <h3 className="text-xl font-semibold">{resource.name}</h3>
                </div>
              </a>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResourceCenter;
