import React, { useState } from "react";
import { Modal, Button, IconButton, Stack } from "@mui/material";
import {
  Close,
  ZoomIn,
  ZoomOut,
  NavigateBefore,
  NavigateNext,
} from "@mui/icons-material"; // Assuming you are using Material-UI icons

const ImageModal = ({ images, initialIndex, onClose, isModalOpen }) => {
  const [currentIndex, setCurrentIndex] = useState(initialIndex);
  const [zoomLevel, setZoomLevel] = useState(100);
  console.log(images);
  const handleZoomIn = () => {
    setZoomLevel((prevZoom) => Math.min(prevZoom + 10, 200)); // Increase zoom by 10%
  };

  const handleZoomOut = () => {
    setZoomLevel((prevZoom) => Math.max(prevZoom - 10, 10)); // Decrease zoom by 10%
  };

  const handleNextImage = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === images.length - 1 ? 0 : prevIndex + 1
    );
  };

  const handlePrevImage = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? images.length - 1 : prevIndex - 1
    );
  };

  const handleImageClose = () => {
    onClose();
  };

  return (
    <Modal open={isModalOpen} onClose={onClose}>
      <div
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          backgroundColor: "black",
        }}
      >
        {/* Main Image */}
        <Stack
          direction="row"
          spacing={2}
          justifyContent="center"
          alignItems="center"
        >
          {/* Previous Image Button */}
          <IconButton
            style={{
              color: "white",
              backgroundColor: "black",
            }}
            onClick={handlePrevImage}
          >
            <NavigateBefore />
          </IconButton>

          {/* Image */}
          <div
            style={{
              position: "relative",
              width: "80vw",
              height: "80vh",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
              src={images[currentIndex]?.image}
              alt="Full-size Image"
              style={{
                maxWidth: "80vw",
                maxHeight: "80vh",
                zoom: `${zoomLevel}%`,
              }}
            />
          </div>

          {/* Next Image Button */}
          <IconButton
            style={{
              color: "white",
              backgroundColor: "black",
            }}
            onClick={handleNextImage}
          >
            <NavigateNext />
          </IconButton>
        </Stack>

        {/* Thumbnails */}
        <Stack
          direction="row"
          spacing={1}
          justifyContent="center"
          alignItems="center"
        >
          {images.map((image, index) => {
            if (image.image == null) return null;
            return (
              <img
                key={index}
                src={image?.image}
                alt={`Thumbnail ${index}`}
                style={{
                  width: "200px",
                  height: "100px",
                  cursor: "pointer",
                  objectFit: "cover",
                  opacity: index === currentIndex ? 0.5 : 1,
                }}
                onClick={() => setCurrentIndex(index)}
              />
            );
          })}
        </Stack>

        {/* Zoom Controls */}
        <Stack
          direction="row"
          spacing={1}
          justifyContent="center"
          alignItems="center"
        >
          <IconButton onClick={handleZoomIn}>
            <ZoomIn
              style={{
                color: "white",
                backgroundColor: "black",
              }}
            />
          </IconButton>
          <IconButton onClick={handleZoomOut}>
            <ZoomOut
              style={{
                color: "white",
                backgroundColor: "black",
              }}
            />
          </IconButton>
        </Stack>

        {/* Close Button */}
        <IconButton
          onClick={handleImageClose}
          style={{
            position: "absolute",
            top: "10px",
            right: "10px",
            color: "white",
            backgroundColor: "black",
          }}
        >
          <Close />
        </IconButton>
      </div>
    </Modal>
  );
};

export default ImageModal;
