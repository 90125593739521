import axios from "axios";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import urls from "../../constants/urls";

import dayjs from "dayjs";

const BlogDetail = () => {
  const { slug } = useParams<{ slug: string }>();
  const [blog, setBlog] = useState<any>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const fetchBlog = async () => {
    setLoading(true);
    if (slug === undefined) return;
    try {
      const response = await axios.get(urls.blogs.detail(slug));
      setBlog(response.data);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching blog:", error);
      setError(true);
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchBlog();
  }, [slug]);

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen">
        Loading...
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex justify-center items-center h-screen text-red-500">
        Error fetching blog
      </div>
    );
  }

  return (
    <div className="container mx-auto py-8">
      <h1 className="text-3xl font-bold mb-4">{blog.title}</h1>
      <p className="text-gray-500 mb-4">
        {dayjs(blog.pub_date).format("DD MMMM YYYY")}
      </p>

      <img
        src={blog.image}
        alt={blog.title}
        className="w-full rounded-md mb-10"
      />
      <div
        className="prose max-w-none"
        dangerouslySetInnerHTML={{ __html: blog.content }}
      ></div>
    </div>
  );
};

export default BlogDetail;
