import React from "react";
import useEmblaCarousel from "embla-carousel-react";
import Autoplay from "embla-carousel-autoplay";

const carouselData = [
  {
    title: "Quality Construction Materials",
    description:
      "M-jenzi provides a wide range of high-quality construction materials sourced from reputable suppliers, ensuring durability and reliability in every project.",
    benefits: [
      "Enhanced structural integrity",
      "Long-lasting construction",
      "Peace of mind for builders and contractors",
    ],
    image: "/images/carousel/quality-construction-materials.jpg",
  },
  {
    title: "Efficient Project Management",
    description:
      "With M-jenzi, experience streamlined project management. From planning to execution, we help you stay on schedule and within budget.",
    benefits: [
      "Timely project completion",
      "Cost-effective solutions",
      "Reduced stress for project managers",
    ],
    image: "/images/carousel/efficient-project-management.jpg",
  },
  {
    title: "Innovative Building Solutions",
    description:
      "Explore innovative and sustainable building solutions with M-jenzi. Our products and recommendations align with the latest industry trends and environmental standards.",
    benefits: [
      "Energy-efficient construction",
      "Environmentally friendly options",
      "Enhanced property value",
    ],
    image: "/images/carousel/innovative-building-solutions.jpg",
  },
  {
    title: "Dedicated Customer Support",
    description:
      "M-jenzi is committed to providing excellent customer support. Our team is ready to assist you at every stage of your project, ensuring a smooth and positive experience.",
    benefits: [
      "Responsive customer service",
      "Expert guidance",
      "Customer satisfaction",
    ],
    image: "/images/carousel/dedicated-customer-support.webp",
  },
  {
    title: "Customized Project Solutions",
    description:
      "Tailor your construction projects with M-jenzi. We offer personalized solutions to meet your unique requirements, ensuring a customized approach to each endeavor.",
    benefits: [
      "Flexibility in project design",
      "Adaptability to specific needs",
      "Optimized construction outcomes",
    ],
    image: "/images/carousel/customized-project-solutions.jpg",
  },
];

const Carousel = () => {
  const [emblaRef, emblaApi] = useEmblaCarousel({ loop: false }, [Autoplay()]);

  const scrollPrev = () => {
    if (emblaApi) emblaApi.scrollPrev();
  };

  const scrollNext = () => {
    if (emblaApi) emblaApi.scrollNext();
  };

  return (
    <div className="embla min-h-screen w-full" ref={emblaRef}>
      <div className="embla__container w-full">
        {carouselData.map((product, index) => (
          <div
            key={index}
            className="embla__slide relative w-full h-screen"
            style={{
              backgroundImage: `url(${product.image})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
              backgroundBlendMode: "multiply",
              backgroundColor: "rgba(0,0,0,0.6)",
            }}
          >
            <div className="embla__content absolute w-full md:w-2/3 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-center text-white flex flex-col items-center justify-center p-4">
              <h2 className="text-3xl md:text-5xl mb-2 font-bold text-white">
                {product.title}
              </h2>
              <p className="text-lg md:text-2xl mb-4 text-gray-50">
                {product.description}
              </p>
            </div>
          </div>
        ))}
      </div>
      <button
        className="absolute top-1/2 left-0 transform -translate-y-1/2 bg-black bg-opacity-50 text-white p-2 rounded-full"
        onClick={scrollPrev}
      >
        &#10094;
      </button>
      <button
        className="absolute top-1/2 right-0 transform -translate-y-1/2 bg-black bg-opacity-50 text-white p-2 rounded-full"
        onClick={scrollNext}
      >
        &#10095;
      </button>
    </div>
  );
};

export default Carousel;
