import React, { useState } from "react";
import { Heart } from "lucide-react";
import { Button } from "@/components/ui/button";
import { Card, CardContent, CardFooter } from "@/components/ui/card";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import { IProduct } from "@/types/product";
import { useNavigate } from "react-router-dom";
interface IProps {
  product: IProduct;
  showFavoriteButton?: boolean;
  shadow?: boolean;
}

const ProductCard = ({
  product,
  shadow = true,
  showFavoriteButton = true,
}: IProps) => {
  const navigate = useNavigate();

  const [isFavorite, setIsFavorite] = useState(false);
  const [openModal, setOpenModal] = useState(false);

  const handleFavoriteButtonClick = () => {
    // Toggle favorite status
    setIsFavorite(!isFavorite);

    const favoriteProducts = localStorage.getItem("favoriteProducts")
      ? JSON.parse(localStorage.getItem("favoriteProducts") as string)
      : [];

    if (isFavorite) {
      const newFavoriteProducts = favoriteProducts.filter(
        (favoriteProduct: IProduct) => favoriteProduct.id !== product.id
      );
      localStorage.setItem(
        "favoriteProducts",
        JSON.stringify(newFavoriteProducts)
      );
    } else {
      const newFavoriteProducts = [...favoriteProducts, product];
      localStorage.setItem(
        "favoriteProducts",
        JSON.stringify(newFavoriteProducts)
      );
    }
  };

  const handleGetQuote = () => {
    // Open modal to inform the user
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleConfirmQuote = () => {
    window.open(`https://wa.me/${product?.vendor?.phone}`, "_blank");
    setOpenModal(false);
  };

  const handleClickCard = () => {
    if (product.item_class === "service") {
      // Open modal for services
      setOpenModal(true);
    } else {
      navigate({
        pathname: `/product-detail`,
        search: `?id=${product.id}`,
      });
    }
  };
  return (
    <>
      <Card
        className={`w-64 h-[350px] overflow-hidden cursor-pointer transition-shadow duration-200 ${
          shadow ? "hover:shadow-lg" : ""
        }`}
        onClick={handleClickCard}
      >
        <div className="relative w-full h-[250px]">
          <img
            className="h-full w-full object-cover"
            src={
              product.icon ||
              "/data:image/jpeg;base64,/9j/4AAQSkZJRgABAQAAAQABAAD/2wCEAAkGBwgHBgkIBwgKCgkLDRYPDQwMDRsUFRAWIB0iIiAdHx8kKDQsJCYxJx8fLT0tMTU3Ojo6Iys/RD84QzQ5OjcBCgoKDQwNGg8PGjclHyU3Nzc3Nzc3Nzc3Nzc3Nzc3Nzc3Nzc3Nzc3Nzc3Nzc3Nzc3Nzc3Nzc3Nzc3Nzc3Nzc3N//AABEIAHgAlgMBIgACEQEDEQH/xAAaAAEAAwEBAQAAAAAAAAAAAAAAAgMEAQUH/8QALRAAAgEDAgQGAQQDAAAAAAAAAAECAwQRITESQXGBIiNCUWGRMlJyobETFGL/xAAUAQEAAAAAAAAAAAAAAAAAAAAA/8QAFBEBAAAAAAAAAAAAAAAAAAAAAP/aAAwDAQACEQMRAD8A+4gAAAAAAAAAAAAAAAAi6kE8Occ9SQAAAAAAAAAAAAA9AAM8rqmtFmRXK7k/xil/IGw45JbtLqefKtVlvJ9it53eoG+VzSj6s9EVyvF6Y/ZXG2qSWdF3LI2a9U2+iAqlc1Hs0uiK5SnPRuUv5N0belH0566liSWywB5koSj+SaN1rLior40I3sc0k/ZkLGX5R7gawAAAAAAAAAAIVlmlJfBMMDzKcOOajnGeZKtBU58KbenM4vLq/tkdryU6snF5XJgI0KsoqSjo9tTjpVFvCX0ehTWIRXskSA8zMo85I6q1VetnpEXCD3ivoDErqqt2n2JK7lzivs0O3pP0LsRdrS5cS6MCmpcqpTlFwaz8ldvUVOpmW2MF7tFyk+6IytJY0kgNEK1ObxGWWWHl08/5IpaPJ6gAAAAAAAAAAAYLqOKzfvqVR1kl7s030dYS7FFFZrQXyB6QAAAyXFxvCm+sjlrWfFwSeU9mwNhGcuGLk9kiRGUeKLi9mgMiu58WsVwmxvw5+DKrR8XilmOTRW8NGeP0gYaHirQfzk9EwWizWXwmbwAAAAAAAAAAApu1mi/h5M1qs118Js1XTxQkZ7JeZJ+yA2mK4uOJ8ENub9yy7dRpKCfC92jI01umuqA4AnkAW1K86iUXolvjmX2lXiXBJ6rYxkoycJKUd0B6ZTdPyJdiynNTgpLmU3r8tL3YFdkvHJ+yNhlslpN/KNQAAAAAAAAAAAZ7x+Vj/optakKblxvGTXVpqpHhZmdpLlNfQGlVqb2nH7JKUXs0zC7WouUX3IuhVXofYDe4xlvFPqiDo0n6F/RixVj+tfZ1V6i9b7gana03smu5F2ceUpIpV1UXNPqiavHzivsC6hRdLOZZTKr5/gup1XkecH2Kbiqqs00tEuYGmzXk592XlVssUIFoAAAAAAAAAAAAAAAAA44p7pPsdAFbo03vCP0RdtSfpx0ZcAM7tIPaTRH/AE1nWbx0NQA5FKKSWyOgAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAf/Z"
            }
            alt={product.name}
            loading="lazy"
          />
          {showFavoriteButton && (
            <Button
              variant="ghost"
              size="icon"
              className="absolute top-2 right-2"
              onClick={handleFavoriteButtonClick}
            >
              <Heart
                className={isFavorite ? "fill-current text-red-500" : ""}
              />
            </Button>
          )}
          {product.item_class === "service" && (
            <div className="absolute top-2 left-2 bg-green-500 text-white px-2 py-1 rounded text-xs">
              Service
            </div>
          )}
        </div>
        <CardContent className="p-4">
          <p className="text-sm font-light h-10 overflow-hidden">
            {product.name}
          </p>
          <p className="text-sm font-bold mt-2">
            {product.item_class === "service"
              ? "Get a Quote"
              : new Intl.NumberFormat("en-KE", {
                  style: "currency",
                  currency: "KES",
                }).format(product.pricing_current)}
          </p>
        </CardContent>
      </Card>

      <Dialog open={openModal} onOpenChange={setOpenModal}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Get a Quote</DialogTitle>
            <DialogDescription>
              You are about to be redirected to the vendor's WhatsApp for a
              quote. If you prefer to call them directly, their phone number is{" "}
              {product?.vendor?.phone}.
            </DialogDescription>
          </DialogHeader>
          <DialogFooter>
            <Button variant="outline" onClick={() => setOpenModal(false)}>
              Cancel
            </Button>
            <Button onClick={handleConfirmQuote}>Visit Vendor WhatsApp</Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default ProductCard;
