import axios from "axios";
import React from "react";
import urls from "../../constants/urls";

const ContactPage: React.FC = () => {
  const [status, setStatus] = React.useState<
    "idle" | "loading" | "error" | "success"
  >("idle");
  const [email, setEmail] = React.useState("");
  const [subject, setSubject] = React.useState("");
  const [message, setMessage] = React.useState("");
  const [phoneNumber, setPhoneNumber] = React.useState("");
  const [error, setError] = React.useState("");

  function onSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();

    setStatus("loading");

    axios
      .post(urls.core.contact, {
        email,
        subject,
        message,
        phone: phoneNumber,
      })
      .then((response) => {
        setStatus("success");
      })
      .catch((error) => {
        setStatus("error");
        setError(error.message);
      });
  }

  return (
    <section className="bg-white dark:bg-gray-900">
      {status === "loading" && (
        <div
          onClick={() => {
            if (status === "loading") {
              return;
            }
            setStatus("idle");
          }}
          className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50"
        >
          <div className="flex items-center space-x-2 text-white">
            <svg
              className="animate-spin h-5 w-5 text-white"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <circle
                className="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                strokeWidth="4"
              ></circle>
              <path
                className="opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647zM12 20c3.042 0 5.824-1.135 7.938-3l-2.647-3A7.962 7.962 0 0112 16v4zm5.291-10H20c0-3.042-1.135-5.824-3-7.938l-3 2.647A7.962 7.962 0 0116 12h4zM12 4c-3.042 0-5.824 1.135-7.938 3l2.647 3A7.962 7.962 0 0112 8V4zm-2.709 7.938l-3 2.647A7.962 7.962 0 014 12h4c0-1.657.51-3.2 1.382-4.465z"
              ></path>
            </svg>
            <span>Loading...</span>
          </div>
        </div>
      )}

      {status === "success" && (
        <div
          className="fixed inset-0 flex  flex-col items-center justify-center bg-black bg-opacity-50 "
          onClick={() => {
            setEmail("");
            // setSubject("");
            setMessage("");
            setPhoneNumber("");

            setStatus("idle");
          }}
        >
          <svg
            className="h-10 w-10 text-green-400"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M5 13l4 4L19 7"
            />
          </svg>

          <span className="text-green-300 font-bold ">
            Your message has been sent. We will get back to you shortly.
          </span>

          <button
            onClick={() => {
              setEmail("");
              // setSubject("");
              setMessage("");
              setPhoneNumber("");

              setStatus("idle");
            }}
            className="mt-4 py-2 px-4 bg-green-600 text-white font-semibold rounded-lg"
          >
            Close or send another message
          </button>
        </div>
      )}

      {status === "error" && (
        <div
          className="fixed inset-0 flex flex-col items-center justify-center bg-white bg-opacity-50"
          onClick={() => {
            setStatus("idle");
          }}
        >
          <svg
            className="h-10 w-10 text-red-400"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>

          <span className="text-red-300 font-bold ">
            Something went wrong. Please try again.
          </span>
        </div>
      )}

      <div className="py-8 lg:py-16 px-4 mx-auto max-w-screen-md">
        <h2 className="mb-4 text-4xl tracking-tight font-extrabold text-center text-gray-900 dark:text-white">
          Contact Us
        </h2>
        <p className="mb-8 lg:mb-16 font-light text-center text-gray-500 dark:text-gray-400 sm:text-xl">
          Got a technical issue? Want to send feedback about a beta feature? Let
          us know.
        </p>
        <form className="space-y-8" onSubmit={onSubmit}>
          <div>
            <label
              htmlFor="email"
              className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
            >
              Your email
            </label>
            <input
              type="email"
              id="email"
              className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500 dark:shadow-sm-light"
              placeholder="name@example.com"
              required
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div>
            <label
              htmlFor="phone-number"
              className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
            >
              Your phone number
            </label>
            <input
              type="tel"
              id="phone-number"
              className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500 dark:shadow-sm-light"
              placeholder="+254 7 xx xxx xxx"
              required
              onChange={(e) => setPhoneNumber(e.target.value)}
            />
          </div>
          <div>
            <label
              htmlFor="subject"
              className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
            >
              Subject
            </label>
            <select
              id="subject"
              className="block p-3 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 shadow-sm focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500 dark:shadow-sm-light"
              required
              onChange={(e) => setSubject(e.target.value)}
            >
              <option value="product-inquiry">Product Inquiry</option>
              <option value="message-to-m-jenzi">Message to M-jenzi</option>
              <option value="feedback">Feedback</option>
              <option value="report-bug">Report a bug</option>
              <option value="affiliate">
                Work with us (Affiliate, Partnership, etc.)
              </option>
            </select>
          </div>
          <div className="sm:col-span-2">
            <label
              htmlFor="message"
              className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-400"
            >
              Your message
            </label>
            <textarea
              id="message"
              rows={6}
              className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg shadow-sm border border-gray-300 focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
              placeholder="Your message."
              defaultValue={""}
              required
              onChange={(e) => setMessage(e.target.value)}
            />
          </div>
          <button
            type="submit"
            className="py-3 px-5 text-sm font-medium text-center text-white rounded-lg bg-primary sm:w-fit hover:bg-green-700 focus:ring-4 focus:outline-none focus:ring-primary-300 dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
          >
            Send message
          </button>
        </form>
      </div>
    </section>
  );
};

export default ContactPage;
