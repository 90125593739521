import React, { useState } from "react";
import { Typography, Box } from "@mui/material";
import { Link } from "react-router-dom";

function QuotationForm() {
  return (
    <Box
      py={2}
      px={5}
      my={10}
      style={{ backgroundColor: "transparent" }}
      className="flex flex-col items-center justify-center rounded-md"
    >
      <Typography variant="h5" gutterBottom className="text-center">
        Looking for a product? You can request for a quotation
      </Typography>
      <Link to="/rfq">
        <button className="bg-red-500 mt-10 hover:bg-red-700 text-white font-bold py-2 px-4 rounded">
          Request for Quotation
        </button>
      </Link>
    </Box>
  );
}

export default QuotationForm;
