import { useState } from "react";
import axios from "axios"; // Import axios for API calls

// Styles
import { Stack, Typography, Button, Tab, Tabs } from "@mui/material";
import theme from "../../theme";
import { sharableInputLabelStyles } from "../../ui/sharableStyles";

// Formik
import { Formik, Form, Field } from "formik";
import { TextField } from "formik-mui";
import * as yup from "yup";
import urls from "../../constants/urls";
import { Link } from "react-router-dom";

const validationSchema = yup.object({
  email: yup.string().email("Enter a valid email"),
  otp: yup.string().required("Enter OTP"),
  newPassword: yup.string().required("Enter a new password"),
});

const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  const [otp, setOtp] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [resetSuccess, setResetSuccess] = useState(false);
  const [requestResetSuccess, setRequestResetSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [tabIndex, setTabIndex] = useState(0);

  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  const handleResetPassword = async () => {
    setLoading(true);
    try {
      // Call the API to reset the password
      await axios.post(urls.auth.password_reset_request, {
        email: email,
      });
      setLoading(false);
      setRequestResetSuccess(true);
      setTabIndex(1);
    } catch (error) {
      console.error("Error sending otp:", error);
      alert(error);
    } finally {
      setLoading(false);
    }
  };

  const handleConfirmReset = async () => {
    setLoading(true);
    try {
      // Call the API to verify OTP and reset password
      await axios.post(urls.auth.verify_otp, {
        email: email,
        otp: otp,
      });
      await axios.post(urls.auth.perform_reset, {
        email: email,
        new_password: newPassword,
        otp: otp,
      });
      setResetSuccess(true);
    } catch (error) {
      console.error("Error confirming reset:", error);
      alert(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Stack
      alignItems="center"
      justifyContent="center"
      sx={{
        padding: "16px",
      }}
    >
      {!resetSuccess && (
        <>
          <Tabs value={tabIndex} onChange={handleTabChange} className="mb-10">
            <Tab label="Reset Password" />
            <Tab label="Confirm Reset" />
          </Tabs>
          <Formik
            initialValues={{ email: "", otp: "", newPassword: "" }}
            validationSchema={validationSchema}
          >
            {() => (
              <Form style={{ width: "100%", maxWidth: "448px" }}>
                <Stack
                  spacing={2}
                  direction="column"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Field
                    component={TextField}
                    type="email"
                    name="email"
                    label="Email"
                    sx={{ ...sharableInputLabelStyles }}
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  {tabIndex === 1 && (
                    <>
                      <Field
                        component={TextField}
                        type="text"
                        name="otp"
                        label="OTP"
                        sx={{ ...sharableInputLabelStyles }}
                        value={otp}
                        onChange={(e) => setOtp(e.target.value)}
                      />
                      <Field
                        component={TextField}
                        type="password"
                        name="newPassword"
                        label="New Password"
                        sx={{ ...sharableInputLabelStyles }}
                        value={newPassword}
                        onChange={(e) => setNewPassword(e.target.value)}
                      />
                    </>
                  )}
                  <Button
                    onClick={
                      tabIndex === 0 ? handleResetPassword : handleConfirmReset
                    }
                    variant="contained"
                    sx={{
                      width: "100%",
                      height: "56px",
                      borderRadius: "56px",
                      backgroundColor: "#FBB03B",
                      textTransform: "none",
                      "&:hover": {
                        backgroundColor: "#ffb53d",
                      },
                      fontSize: "13px",
                      fontWeight: theme.fontWeight.semiBold,
                    }}
                    disabled={loading}
                  >
                    {loading
                      ? "Loading..."
                      : tabIndex === 0
                      ? "Reset Password"
                      : "Confirm Reset"}
                  </Button>
                </Stack>
              </Form>
            )}
          </Formik>
        </>
      )}
      {requestResetSuccess && !resetSuccess && (
        <div className="text-center">
          <Typography
            sx={{
              fontSize: "22px",
              fontWeight: theme.fontWeight.semiBold,
              marginTop: "24px",
            }}
          >
            OTP Sent Successfully
          </Typography>
          <Typography sx={{ marginTop: "24px" }}>
            Please check your email for the OTP
          </Typography>
        </div>
      )}
      {resetSuccess && (
        <Typography
          sx={{
            fontSize: "16px",
            fontWeight: theme.fontWeight.semiBold,
            marginTop: "24px",
          }}
        >
          Password Reset Successful, Please
          <Link to="/login" style={{ color: theme.palette.primary.main }}>
            {" "}
            Login here
          </Link>
        </Typography>
      )}
    </Stack>
  );
};

export default ForgotPassword;
