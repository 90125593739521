import React, { useEffect, useState } from "react";
import axios from "axios";
import urls from "../../constants/urls";
import { Skeleton } from "@mui/material";
// import check icon from react icons
import { FaCheck } from "react-icons/fa";

const CustomerAdSection = ({
  fullwidth,
  adIndex,
}: {
  fullwidth?: boolean;
  adIndex?: number;
}) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [status, setStatus] = useState("idle");
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");
  const [fetchedAd, setFetchedAd] = useState<{
    image: string;
    url: string;
    is_active: string;
    date_created: string;
    index: string;
  } | null>(null);
  const [loadingAd, setLoadingAd] = useState(true);
  const [adError, setAdError] = useState("");

  // fetch ad if there is the ad index
  const fetchAd = async () => {
    if (!adIndex) return;
    try {
      setLoadingAd(true);
      setAdError("");
      const response = await axios.get(urls.items.advertDetail(adIndex));
      setFetchedAd(response.data);
      console.log("Fetched ad:", response.data);
    } catch (error: any) {
      setAdError(error.message);
      console.error("Error fetching ad:", error);
    } finally {
      setLoadingAd(false);
    }
  };

  useEffect(() => {
    if (adIndex) {
      fetchAd();
    } else {
      setLoadingAd(false);
    }
  }, [adIndex]);

  const onSubmit = (e) => {
    e.preventDefault();

    // Validate form
    if (!email || !name || !phoneNumber || !message) {
      setError("Please fill in all fields");
      return;
    }
    setStatus("loading");

    axios
      .post(urls.core.contact, {
        email,
        subject: "Ad Space RequestRequest for index " + adIndex,
        message,
        phone: phoneNumber,
      })
      .then((response) => {
        setStatus("success");
        // timeout to allow user to see success message
        setTimeout(() => {
          setModalOpen(false);
        }, 4000);
      })
      .catch((error) => {
        setStatus("error");
        setError(error.message);
      });
  };

  return (
    <div className="w-[80vw] md:w-full mt-10 min-h-[50vh] md:px-10 flex items-center justify-center bg-gray-50 rounded">
      {/* Main content goes here */}
      <div className="w-screen md:w-[100%] py-2 rounded-shadow">
        <>
          {adIndex ? (
            <>
              {loadingAd ? (
                <div className="w-full h-96 bg-transparent flex items-center justify-center">
                  <Skeleton variant="rectangular" width={300} height={200} />
                </div>
              ) : fetchedAd !== null ? (
                <a
                  href={fetchedAd["url"]}
                  target="_blank"
                  className="w-full h-96 bg-contain  rounded relative"
                >
                  <img src={fetchedAd["image"]} className="w-full h-full" />
                  {/* text denoting this is an advert at the bottom and a link advertise here(they do not block the image) */}
                  {/* <div className="absolute bottom-2 right-2">
                    <p className="text-white bg-red-600 px-2 py-1 rounded">
                      Ad
                    </p>
                  </div> */}
                </a>
              ) : adError !== null ? (
                <>
                  <h1 className="text-center text-2xl mb-4">
                    Advertise with M-jenzi
                  </h1>
                  <button
                    onClick={() => setModalOpen(true)}
                    className="w-full py-2 bg-gray-200  text-red-600 font-semibold rounded"
                  >
                    Advertise Here
                  </button>
                </>
              ) : null}
            </>
          ) : (
            <>
              <h1 className="text-center text-2xl mb-4">
                Advertise with M-jenzi
              </h1>
              <button
                onClick={() => setModalOpen(true)}
                className="w-full py-2 bg-gray-200  text-red-600 font-semibold rounded"
              >
                Advertise Here
              </button>
            </>
          )}
        </>
        {fetchedAd && (
          <a
            onClick={() => setModalOpen(true)}
            className=" text-red-600 px-2 py-1 underline cursor-pointer text-center mt-2"
          >
            Advertise with us
          </a>
        )}
      </div>
      {/* Modal goes here */}
      {modalOpen && (
        <div className="fixed  top-0 left-0 w-screen h-screen flex items-center justify-center bg-black bg-opacity-60">
          <form
            onSubmit={onSubmit}
            className="bg-white w-11/12 sm:w-5/12 p-6 rounded"
          >
            <div className="flex flex-row w-full justify-between">
              <h2 className="text-2xl mb-4">Request Ad Space</h2>
              {/* close icon */}
              <div
                onClick={() => setModalOpen(false)}
                className="w-8 h-8 flex items-center justify-center rounded-full bg-white text-black cursor-pointer"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6 text-red-600"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </div>
            </div>
            {/* Form */}
            {status === "success" ? (
              <div className="text-center flex flex-col min-h-[30vh] justify-center items-center">
                {/* done icon from react icons */}
                <FaCheck className="text-4xl text-green-600" />

                <p
                  className="text-green-600 font-semibold"
                  style={{ fontSize: "1.5rem" }}
                >
                  Request sent successfully!
                </p>
                <p>Our team will get back to you shortly</p>
              </div>
            ) : (
              <>
                <input
                  onChange={(e) => setName(e.target.value)}
                  type="text"
                  placeholder="Your Name"
                  className="w-full mb-2 py-1 px-2 border border-gray-400 rounded"
                />
                <input
                  onChange={(e) => setEmail(e.target.value)}
                  type="email"
                  placeholder="Your Email"
                  className="w-full mb-2 py-1 px-2 border border-gray-400 rounded"
                />
                <input
                  onChange={(e) => setPhoneNumber(e.target.value)}
                  type="tel"
                  placeholder="Your Phone Number"
                  className="w-full mb-2 py-1 px-2 border border-gray-400 rounded"
                />
                <textarea
                  onChange={(e) => setMessage(e.target.value)}
                  type="text"
                  placeholder="Your Message"
                  className="w-full mb-2 py-1 px-2 h-20 border border-gray-400 rounded"
                />
                <button
                  type="submit"
                  className={`w-full py-2 rounded ${
                    status === "loading" ? "bg-gray-500" : "bg-[#f44336]"
                  } text-white font-semibold`}
                  disabled={status === "loading"}
                >
                  {status === "loading" ? "Loading..." : "Submit"}
                </button>
              </>
            )}
            {error && <p className="mt-2 text-red-600">{error}</p>}
          </form>
        </div>
      )}
    </div>
  );
};

export default CustomerAdSection;
