import React from "react";
import {
  Box,
  Avatar,
  Typography,
  IconButton,
  useMediaQuery,
  Theme,
} from "@mui/material";
import { ArrowForward } from "@mui/icons-material";
import Vendor from "@/types/vendor";

const VendorSection = ({ vendor }: { vendor: Vendor }) => {
  const isSmallScreen = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm")
  );
  const isMediumScreen = useMediaQuery((theme: Theme) =>
    theme.breakpoints.between("sm", "md")
  );

  return (
    <Box
      display="flex"
      flexDirection={isSmallScreen ? "column" : "row"}
      alignItems={isSmallScreen ? "flex-start" : "center"}
      justifyContent="space-between"
      gap={2}
    >
      <Box display="flex" alignItems="center">
        {vendor.icon && (
          <Avatar
            src={vendor.icon}
            alt={vendor.name}
            sx={{
              objectFit: "contain",
              marginRight: 1,
              borderRadius: 2,
              width: 40,
              height: 40,
              backgroundColor: "#f4f4f4",
            }}
          />
        )}
        <Typography variant="body1">{vendor.name}</Typography>
      </Box>

      <Box
        display="flex"
        flexDirection={isSmallScreen ? "column" : "row"}
        alignItems={isSmallScreen ? "flex-start" : "center"}
        gap={2}
        width={isSmallScreen ? "100%" : "auto"}
      >
        <Box
          component={"a"}
          href={`/vendors/${vendor.slug}`}
          display="flex"
          alignItems="center"
          justifyContent="center"
          sx={{
            "&:hover": {
              cursor: "pointer",
              backgroundColor: "#ebebeb",
              color: "#000",
            },
            backgroundColor: "#f4f4f4",
            borderRadius: 2,
            padding: 1,
            borderColor: "#d1d1d1",
            width: isSmallScreen ? "100%" : "auto",
            textDecoration: "none",
            color: "inherit",
          }}
        >
          <Typography variant="body2" mr={1}>
            View Vendor Products
          </Typography>
          <ArrowForward fontSize="small" />
        </Box>

        {vendor.phone && (
          <Box
            component="a"
            href={`https://wa.me/${vendor.phone}?text=Hello%20${vendor.name}. I%20am%20interested%20in%20your%20products.`}
            target="_blank"
            aria-label="Contact vendor on WhatsApp"
            display="flex"
            alignItems="center"
            justifyContent="center"
            sx={{
              "&:hover": {
                cursor: "pointer",
                backgroundColor: "#ebebeb",
                color: "#000",
              },
              backgroundColor: "#f4f4f4",
              borderRadius: 2,
              padding: 1,
              borderColor: "#d1d1d1",
              width: isSmallScreen ? "100%" : "auto",
              textDecoration: "none",
              color: "inherit",
            }}
          >
            <Typography variant="body2" mr={1}>
              Chat with Vendor
            </Typography>
            <IconButton size="small">
              <img
                src="https://upload.wikimedia.org/wikipedia/commons/6/6b/WhatsApp.svg"
                alt="WhatsApp"
                width="20"
              />
            </IconButton>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default VendorSection;
