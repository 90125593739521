// Styles
import { Stack, Box, Typography } from "@mui/material";
import theme from "../../theme";

import {
  ContactSupport,
  VerifiedUserOutlined,
  MoneyRounded,
  PaymentOutlined,
} from "@mui/icons-material";
import Colors from "../../constants/colors";
// Updated listData.json
const listData = [
  {
    id: "rates",
    title: "BEST RATES",
    description: "Best prices in Kenya",
  },
  {
    id: "vendors",
    title: "VETTED VENDORS",
    description: "All vendors verified",
  },
  {
    id: "payment",
    title: "SECURE PAYMENT",
    description: "100% secure payment",
  },
  {
    id: "support",
    title: "24/7 SUPPORT",
    description: "Dedicated support",
  },
];

// Updated WhyChooseUs component

// Styles and Imports remain the same

const WhyChooseUs = () => {
  const renderIcon = (iconName: string) => {
    switch (iconName) {
      case "rates":
        return (
          <MoneyRounded
            id={iconName}
            style={{
              color: Colors.primary,
            }}
          />
        );
      case "vendors":
        return (
          <VerifiedUserOutlined
            id={iconName}
            style={{
              color: Colors.primary,
            }}
          />
        );
      case "payment":
        return (
          <PaymentOutlined
            id={iconName}
            style={{
              color: Colors.primary,
            }}
          />
        );
      case "support":
        return (
          <ContactSupport
            id={iconName}
            style={{
              color: Colors.primary,
            }}
          />
        );
      default:
        return null;
    }
  };

  const renderList = () => {
    return listData.map((item, index) => (
      <Stack
        data-aos="fade-up"
        data-aos-delay={index * 200}
        data-aos-duration="1000"
        key={item.id}
        sx={{ width: "244px" }}
        className={
          "shadow-lg bg-white rounded-lg py-10 hover:shadow-xl  flex flex-col justify-center items-center "
        }
      >
        {renderIcon(item.id)}
        <Typography
          component="h3"
          sx={{
            marginTop: { xs: "22px", sm: "43px" },
            marginBottom: "20px",
            fontSize: "16px",
            fontWeight: theme.fontWeight.semiBold,
          }}
        >
          {item.title}
        </Typography>
        <Typography
          sx={{
            color: "#808080",
            height: "96px",
            fontWeight: theme.fontWeight.regular,
            fontSize: "13px",
          }}
        >
          {item.description}
        </Typography>
      </Stack>
    ));
  };

  return (
    <Stack
      component="section"
      sx={{
        alignItems: "center",
        rowGap: "30px",
        marginTop: "30px",
      }}
    >
      <Box component="header" sx={{ width: "100%" }}>
        <Typography
          align="center"
          component="h2"
          sx={{
            fontSize: { xs: "21px", lg: "29px" },
            fontWeight: theme.fontWeight.light,
          }}
        >
          Why should you choose M-Jenzi?
        </Typography>
      </Box>
      <Stack
        direction="row"
        sx={{
          flexWrap: "wrap",
          alignItems: "center",
          justifyContent: "center",
          columnGap: { xs: "20px", sm: "40px", lg: "50px", xl: "93px" },
          rowGap: "50px",
          maxWidth: { xs: "700px", lg: "initial" },
          padding: { xs: "20px", lg: "30px" },
        }}
      >
        {renderList()}
      </Stack>
    </Stack>
  );
};

export default WhyChooseUs;
