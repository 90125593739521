import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { IProduct } from "@/types/product";
import { doc, getDocFromServer } from "firebase/firestore";
import { db } from "../../firebase";
import axios from "axios";
import urls from "../../constants/urls";
import storagekeys from "../../constants/storagekeys";

interface IinitialState {
  product: IProduct | null;
  loading: boolean;
}

const initialState: IinitialState = {
  product: null,
  loading: false,
};

const productSlice = createSlice({
  name: "productSlice",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchProduct.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchProduct.fulfilled, (state, action) => {
        state.product = action.payload;
        state.loading = false;
      });
  },
});

interface IFetchProductPayload {
  productId: string;
  favoriteProductIds: string[] | undefined;
}

export const fetchProduct = createAsyncThunk(
  "product/fetchProduct",
  async ({ productId, favoriteProductIds }: IFetchProductPayload, thunkAPI) => {
    const storedUser = localStorage.getItem(storagekeys.user);
    const user = storedUser ? JSON.parse(storedUser) : null;
    const resp = await axios.get<IProduct>(urls.items.detail(productId), {
      headers: {},
    });
    if (resp.status === 200) {
      return resp.data;
    }
    return null;
  }
);

export default productSlice.reducer;
export const {} = productSlice.actions;
