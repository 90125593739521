import { useEffect, useState } from "react";
import axios from "axios";
import urls from "../constants/urls";
import { useAppSelector } from "../app/store";
import { Category } from "../types/product";
export const useCategories = () => {
  const [categories, setCategories] = useState<Category[]>([]);
  const [status, setStatus] = useState<
    "idle" | "loading" | "success" | "error"
  >("idle");

  const user = useAppSelector((state) => state.user);
  //   console.log(user.user.token);

  const fetchCategories = async () => {
    setStatus("loading");
    axios
      .get(urls.items.categories, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((resp) => {
        setCategories(resp.data);
        setStatus("success");
      })
      .catch((error) => {
        console.log(error);
        setStatus("error");
      });
  };
  useEffect(() => {
    fetchCategories();
  }, []);

  return { categories, status };
};
