import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

// Styles
import {
  Avatar,
  Box,
  Typography,
  Stack,
  Link,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  CircularProgress,
} from "@mui/material";
import theme from "../../theme";
import { Close as CloseIcon } from "@mui/icons-material";

// Data
import { IModifiedProduct } from "@/types/product";
import { arrayRemove, doc, updateDoc } from "firebase/firestore";
import { db } from "../../firebase";
import { RootState, useAppDispatch } from "../../app/store";
import {
  fetchAllProducts,
  setCartProductsAndTotalCost,
} from "../../features/products/productsSlice";

// Components
import Counter from "../Counter";

import {
  addUserProductsInCart,
  removeUserProductFromCart,
  updateUserProductQuantityInCart,
} from "../../helpers/addUserProductsInCart";
import { useCart } from "../../hooks/useCart";
import { setUserProductsInCart } from "../../features/user/userSlice";
import { set } from "lodash";

const CartProduct = ({
  cart_item,
  user,
  dispatch,
}: {
  cart_item: any;
  user: any;
  dispatch: any;
}) => {
  const product = cart_item.item;
  const item = cart_item;
  const url = `/product-detail?id=${product.id}`;

  const [updateQuantityStatus, setUpdateQuantityStatus] = useState<
    "idle" | "loading" | "error" | "success"
  >("idle");
  const handleClickAmountButtons = async (
    productId: number,
    operation: "increase" | "decrease"
  ) => {
    const product = user?.userProductsInCart?.find(
      (product) => product.item.id === productId
    );
    // console.log("product", product);
    if (!product) return;

    setUpdateQuantityStatus("loading");

    try {
      const resp = await updateUserProductQuantityInCart({
        product: product.item.id,
        quantity:
          operation === "increase"
            ? product.quantity + 1
            : product.quantity - 1,
      });
      if (resp.status == 200 || 201) {
        const item = product.item;
        const quantity = product.quantity;
        // if (operation === "increase" && quantity === item.stock) return;
        // if (operation === "decrease" && quantity === 1) return;
        const newQuantity =
          operation === "increase" ? quantity + 1 : quantity - 1;
        setUpdateQuantityStatus("success");
        const newProducts = user?.userProductsInCart?.map((product) => {
          if (product.item.id === productId) {
            return { ...product, quantity: newQuantity };
          }

          return product;
        });

        console.log("products", newProducts);

        // Update the user's cart in the store
        if (newProducts) dispatch(setUserProductsInCart(newProducts));
      }
    } catch (error) {
      setUpdateQuantityStatus("error");
      console.log("Error updating user cart", error);
    }
  };

  const [deleteStatus, setDeleteStatus] = useState<
    "idle" | "loading" | "error" | "success"
  >("idle");

  const handleDeleteItemInCart = async (id: number) => {
    try {
      setDeleteStatus("loading");

      const resp = await removeUserProductFromCart({
        product: id,
      });
      if (resp.status === 200 || 201) {
        setDeleteStatus("success");
        const newProducts = user?.userProductsInCart?.filter(
          (product) => product.item.id !== id
        );
        if (newProducts) dispatch(setUserProductsInCart(newProducts));
      }
    } catch (error) {
      setDeleteStatus("error");
      console.log("Error deleting item from cart", error);
    }
  };
  return (
    <TableRow key={product.id} sx={{ "& td": { borderBottom: 0 } }}>
      <TableCell sx={{ minWidth: "300px" }}>
        <Stack direction="row" alignItems="center" columnGap="24px">
          <Link href={url}>
            <Avatar
              src={product.icon}
              sx={{ width: "70px", height: "70px" }}
              alt={product.name}
            />
          </Link>
          <Link href={url} sx={{ color: "initial", textDecoration: "none" }}>
            <Typography fontSize="16px" fontWeight={theme.fontWeight.semiBold}>
              {product.name}
            </Typography>
          </Link>
        </Stack>
      </TableCell>
      <TableCell sx={{ minWidth: "200px" }}>
        <Counter
          sx={{ marginLeft: "auto", marginRight: "auto" }}
          maxValue={50}
          counterValue={item.quantity}
          handleClickDecreaseButton={() =>
            handleClickAmountButtons(product.id, "decrease")
          }
          handleClickIncreaseButton={() =>
            handleClickAmountButtons(product.id, "increase")
          }
        />
        {updateQuantityStatus === "error" ? (
          <Typography color="red">Error updating quantity</Typography>
        ) : updateQuantityStatus === "success" ? (
          <></>
        ) : updateQuantityStatus === "loading" ? (
          <CircularProgress />
        ) : (
          <></>
        )}
      </TableCell>
      <TableCell sx={{ minWidth: "100px" }}>
        <Typography
          fontSize="16px"
          fontWeight={theme.fontWeight.semiBold}
          textAlign="center"
        >
          KES {Number(product.pricing_current).toFixed(2)}
        </Typography>
      </TableCell>
      <TableCell align="right" sx={{ minWidth: "100px" }}>
        <CloseIcon
          sx={{ color: "#000000", cursor: "pointer" }}
          onClick={() => handleDeleteItemInCart(product.id)}
        />
        {deleteStatus === "error" ? (
          <Typography color="red">Error deleting item</Typography>
        ) : deleteStatus === "success" ? (
          <></>
        ) : deleteStatus === "loading" ? (
          <CircularProgress />
        ) : (
          <></>
        )}
      </TableCell>
    </TableRow>
  );
};
const CartProductsRenderer = () => {
  const {
    user: { user },
    currentUser,
    products: { products, cartProducts },
  } = useSelector((state: RootState) => state);
  const dispatch = useAppDispatch();

  const { cartStatus } = useCart();

  useEffect(() => {
    if (user) {
      dispatch(setCartProductsAndTotalCost(user.userProductsInCart));
    }
  }, [user, products]);

  useEffect(() => {}, [user]);

  return (
    <Box sx={{ minHeight: "400px" }}>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }}>
          <TableHead>
            <TableRow>
              <TableCell
                sx={{
                  color: "#c1c1c1",
                  fontSize: "14px",
                  fontWeight: theme.fontWeight.regular,
                  minWidth: "300px",
                }}
              >
                Product
              </TableCell>
              <TableCell
                align="center"
                sx={{
                  color: "#c1c1c1",
                  fontSize: "14px",
                  fontWeight: theme.fontWeight.regular,
                  minWidth: "200px",
                }}
              >
                Amount
              </TableCell>
              <TableCell
                align="center"
                sx={{
                  color: "#c1c1c1",
                  fontSize: "14px",
                  fontWeight: theme.fontWeight.regular,
                  minWidth: "100px",
                }}
              >
                Price
              </TableCell>
              <TableCell
                align="center"
                sx={{
                  minWidth: "100px",
                }}
              ></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {cartStatus === "loading" ? (
              <TableRow>
                <CircularProgress />
              </TableRow>
            ) : cartStatus === "error" ? (
              <TableRow>
                <Typography>There was an error loading your cart.</Typography>
              </TableRow>
            ) : (
              <></>
            )}
            {user?.userProductsInCart?.map((cart_item) => {
              const product = cart_item.item;
              const item = cart_item;
              const url = `/product-detail?id=${product.id}`;
              return (
                <CartProduct
                  cart_item={cart_item}
                  user={user}
                  dispatch={dispatch}
                />
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default CartProductsRenderer;
