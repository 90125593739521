import React, { useState } from "react";

import { Stack, Typography } from "@mui/material";

import {
  DiscoverIcon,
  IdealIcon,
  MaestroIcon,
  MastercardIcon,
  PayPalIcon,
  VISAIcon,
} from "../../ui";
import PaymentMethodCard from "../PaymentMethodCard";

import paymentMethodNames from "../../constants/paymentMethodNames.json";

const PaymentMethodsRenderer = () => {
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(
    paymentMethodNames[0]
  );

  const handleClickPaymentCard = (methodName: string) => {
    setSelectedPaymentMethod(methodName);
  };

  const findAndReturnPaymentIcon = (methodName: string) => {
    switch (methodName) {
      case "paypal":
        return <PayPalIcon id={methodName} />;
      case "visa":
        return <VISAIcon id={methodName} />;
      case "mastercard":
        return <MastercardIcon id={methodName} />;
      case "maestro":
        return <MaestroIcon id={methodName} />;
      case "discover":
        return <DiscoverIcon id={methodName} />;
      case "ideal":
        return <IdealIcon id={methodName} />;

      case "m-pesa":
        return (
          <div id={methodName}>
            <img
              src="https://upload.wikimedia.org/wikipedia/commons/thumb/1/15/M-PESA_LOGO-01.svg/1200px-M-PESA_LOGO-01.svg.png"
              alt="m-pesa"
            />
          </div>
        );
    }
  };

  const renderMethods = () => {
    return paymentMethodNames.map((methodName) => {
      const icon = findAndReturnPaymentIcon(methodName);
      return (
        <React.Fragment key={methodName}>
          <PaymentMethodCard
            icon={icon!}
            isSelected={methodName === selectedPaymentMethod}
            onClick={() => {
              handleClickPaymentCard(methodName);
            }}
            methodName={methodName}
          />
        </React.Fragment>
      );
    });
  };

  return (
    <Stack
      direction="column"
      // flexWrap="wrap"
      justifyContent="center"
      gap="10px"
      sx={{ maxWidth: "410px" }}
    >
      {/* {renderMethods()} */}

      {/* Pay with M-pesa */}
      <Typography variant="body1" sx={{ textAlign: "center" }}>
        Pay with M-pesa
      </Typography>
      <PaymentMethodCard
        icon={
          <div id="m-pesa">
            <img
              src="https://upload.wikimedia.org/wikipedia/commons/thumb/1/15/M-PESA_LOGO-01.svg/1200px-M-PESA_LOGO-01.svg.png"
              alt="m-pesa"
            />
          </div>
        }
        isSelected={"m-pesa" === selectedPaymentMethod}
        onClick={() => {
          handleClickPaymentCard("m-pesa");
        }}
        methodName="m-pesa"
      />
    </Stack>
  );
};

export default PaymentMethodsRenderer;
