// Import necessary React and Material-UI components
import React from "react";
import { Grid, Paper, Typography } from "@mui/material";

// Define an array of partner logos (replace these with your actual logo images)
const partners = [
  {
    name: "Man Pro ",
    logo: "/images/partners/manpro.png",
    link: '"https://manpro.co.ke',
    backgroundColor: "#ffffff",
  },
  {
    name: "Paybills.Africa",
    logo: "/images/partners/PaybillsAfricaLogo.svg",
    link: "https://www.paybills.africa",
  },

  {
    name: "Flexpay",
    logo: "/images/partners/flexpay.webp",
    link: "https://www.flexpay.co.ke/",
  },

  // Add more logos as needed
];
import Slider from "react-infinite-logo-slider";

const PartnersSection = () => {
  return (
    <div className="container mx-auto py-8">
      <Typography variant="h6" gutterBottom>
        Our Partners
      </Typography>
      <Slider
        width="450px"
        duration={40}
        pauseOnHover={true}
        blurBorders={false}
        blurBoderColor={"#fff"}
      >
        {partners.map((partner, index) => (
          <Slider.Slide className="mx-10 items-center flex justify-center">
            <Paper
              elevation={0}
              style={{
                padding: "10px",
                textAlign: "center",
                backgroundColor: "rgba(255, 255, 255, 0)",
              }}
              className="shadow-sm hover:shadow-xl"
            >
              <a href={partner.link} target="_blank" rel="noreferrer">
                <img
                  src={partner.logo}
                  alt={partner.name}
                  style={{
                    maxWidth: "100%",
                    maxHeight: "100%",
                    height: "100px",
                  }}
                />
              </a>
            </Paper>
          </Slider.Slide>
        ))}
      </Slider>
    </div>
  );
};

export default PartnersSection;
