import axios from "axios";
import React from "react";
import urls from "../../constants/urls";
import { stat } from "fs";
import { Loading } from "../../components";
import dayjs from "dayjs";

interface Blog {
  id: number;
  title: string;
  author: {
    username: string;
  };
  image: string;
  pub_date: string;
  content: string;
  slug: string;
}

interface BlogCardProps {
  blog: Blog;
}

const placeholderImage =
  "https://i0.wp.com/thinkfirstcommunication.com/wp-content/uploads/2022/05/placeholder-1-1.png?fit=1200%2C800&ssl=1";

const BlogCard: React.FC<BlogCardProps> = ({ blog }) => {
  return (
    <a href={`/blog/${blog.slug}`} className="block">
      <div className="bg-white rounded-lg shadow-md p-6">
        <h2 className="text-xl font-bold mb-2">{blog.title}</h2>
        <p className="text-gray-500 mb-4">
          By {blog?.author?.username} |{" "}
          {dayjs(blog.pub_date).format("DD MMMM YYYY")}
        </p>
        <img
          src={blog.image || placeholderImage}
          alt={blog.title}
          className="mb-4"
        />
        {/* render the blog content as html */}
        <div
          dangerouslySetInnerHTML={{
            __html:
              blog.content.length > 200
                ? blog.content.substring(0, 200) + "..."
                : blog.content,
          }}
        ></div>
        {/* read more */}
        <a href={`/blog/${blog.slug}`} className="text-purple-500">
          Read more
        </a>
      </div>
    </a>
  );
};

const BlogPage: React.FC = () => {
  const [blogs, setBlogs] = React.useState<Blog[]>([]);

  const [status, setStatus] = React.useState<
    "idle" | "loading" | "error" | "success"
  >("idle");

  function fetchBlogs() {
    setStatus("loading");
    axios
      .get(urls.blogs.all)
      .then((response) => {
        setStatus("success");
        setBlogs(response.data);
      })
      .catch((error) => {
        setStatus("error");
      });
  }

  React.useEffect(() => {
    fetchBlogs();
  }, []);

  return (
    <div className="container mx-auto py-8">
      <div className="text-center items-center flex flex-col">
        {status == "loading" ? (
          <div className="text-2xl font-bold">
            <Loading />
          </div>
        ) : status == "error" ? (
          <div className="text-2xl font-bold">Error</div>
        ) : status == "success" && blogs.length == 0 ? (
          <div className="text-2xl font-bold">No blogs found</div>
        ) : (
          <div className="text-2xl font-bold">Blogs</div>
        )}
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
        {blogs.map((blog) => (
          <BlogCard key={blog.id} blog={blog} />
        ))}
      </div>
    </div>
  );
};

export default BlogPage;
