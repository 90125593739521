import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { Phone, Mail, MapPin, MessageSquare } from "lucide-react";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { Button } from "@/components/ui/button";
import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar";
import { Skeleton } from "@/components/ui/skeleton";
import { Separator } from "@/components/ui/separator";
import { ProductCard } from "@/components";
import useVendorDetail from "../../hooks/useVendorDetail";
import urls from "../../constants/urls";
import { IProduct } from "@/types/product";

const SkeletonProductCard = () => (
  <Card className="h-full">
    <Skeleton className="aspect-square w-full" />
    <CardContent className="p-4">
      <Skeleton className="h-4 w-2/3 mb-2" />
      <Skeleton className="h-3 w-full mb-2" />
      <Skeleton className="h-3 w-1/2" />
    </CardContent>
  </Card>
);

const VendorDetailPage = () => {
  const { vendor_slug } = useParams<{ vendor_slug: string }>();
  const slug = vendor_slug;

  const {
    vendor,
    status: vendorStatus,
    fetchVendorDetail,
  } = useVendorDetail(slug);

  const [products, setProducts] = useState<IProduct[]>([]);
  const [status, setStatus] = useState<
    "idle" | "loading" | "success" | "error"
  >("idle");

  const fetchProducts = async () => {
    setStatus("loading");
    axios
      .get(urls.items.all + `?vendor=${vendor?.id}` + `&limit=10`)
      .then((res) => {
        setProducts(res.data.results);
        setStatus("success");
      })
      .catch((err) => {
        setStatus("error");
      });
  };

  useEffect(() => {
    if (vendor && products.length === 0) {
      fetchProducts();
    }
  }, [vendor]);

  if (vendorStatus === "loading" || status === "loading") {
    return (
      <div className="container mx-auto px-4 py-8">
        <Skeleton className="h-8 w-1/2 mb-4" />
        <Skeleton className="h-64 w-full mb-8" />
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mb-8">
          <Skeleton className="h-64" />
          <Skeleton className="h-64" />
        </div>
        <Skeleton className="h-8 w-1/3 mb-4" />
        <div className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 gap-4">
          {[...Array(8)].map((_, index) => (
            <SkeletonProductCard key={index} />
          ))}
        </div>
      </div>
    );
  }

  if (vendorStatus === "error" || !vendor) {
    return (
      <div className="container mx-auto px-4 py-8">
        <h1 className="text-2xl font-bold mb-4">
          Error loading vendor details. Please try again.
        </h1>
      </div>
    );
  }

  return (
    <div className="container mx-auto px-4 py-8">
      <div className="flex flex-col md:flex-row justify-between items-start md:items-center mb-8">
        <h1 className="text-3xl font-bold mb-4 md:mb-0">{vendor.name}</h1>
        <Button
          className="bg-green-500 hover:bg-green-600"
          onClick={() => window.open(`https://wa.me/${vendor.phone}`, "_blank")}
        >
          <MessageSquare className="mr-2 h-4 w-4" />
          Message on WhatsApp
        </Button>
      </div>

      {vendor.banner && (
        <div className="relative h-64 md:h-80 mb-8 overflow-hidden rounded-lg">
          <img
            src={vendor.banner}
            alt="Vendor Banner"
            className="w-full h-full object-cover"
          />
        </div>
      )}

      <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mb-12">
        <Card>
          <CardHeader>
            <CardTitle>Vendor Information</CardTitle>
          </CardHeader>
          <CardContent>
            <div className="flex items-center space-x-4 mb-4">
              <Avatar className="h-16 w-16">
                <AvatarImage src={vendor.icon} alt={vendor.name} />
                <AvatarFallback>{vendor.name.charAt(0)}</AvatarFallback>
              </Avatar>
              <div>
                <p className="font-semibold">{vendor.company}</p>
                <p className="text-sm text-gray-500">{vendor.shop}</p>
              </div>
            </div>
            <Separator className="my-4" />
            <div className="space-y-2">
              <div className="flex items-center">
                <Mail className="mr-2 h-4 w-4" />
                <span>{vendor.email}</span>
              </div>
              <div className="flex items-center">
                <Phone className="mr-2 h-4 w-4" />
                <span>{vendor.phone}</span>
              </div>
              <div className="flex items-center">
                <MapPin className="mr-2 h-4 w-4" />
                <span>{`${vendor.location}, ${vendor.county}, ${vendor.country}`}</span>
              </div>
            </div>
          </CardContent>
        </Card>

        <Card>
          <CardHeader>
            <CardTitle>About Us</CardTitle>
          </CardHeader>
          <CardContent>
            <p className="text-gray-700">{vendor.description}</p>
          </CardContent>
        </Card>
      </div>

      <h2 className="text-2xl font-semibold mb-4 text-center">
        Featured Products
      </h2>
      {status === "error" && (
        <p className="text-red-500 mb-4 text-center">
          Error loading products. Please try again.
        </p>
      )}
      {status === "success" && (
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 mb-8">
          {products.map((product: IProduct) => (
            <ProductCard key={product.id} product={product} />
          ))}
        </div>
      )}

      <div className="text-center">
        <Button variant="outline">View All Products</Button>
      </div>
    </div>
  );
};

export default VendorDetailPage;
