import React, { useState } from "react";
import axios from "axios";
import urls from "../../constants/urls";
import { CircularProgress } from "@mui/material";
import {
  AiOutlineClose,
  AiOutlineCheck,
  AiOutlineExclamation,
} from "react-icons/ai";

function ProductsSection({
  products,
  setProducts,
}: {
  products: any;
  setProducts: any;
}) {
  const [itemName, setItemName] = useState("");
  const [quantity, setQuantity] = useState(0);
  const [maxBudget, setMaxBudget] = useState(0);
  const [minBudget, setMinBudget] = useState(0);
  const [description, setDescription] = useState("");

  const addProduct = (e) => {
    // validate fields
    if (itemName === "") {
      alert("Item Name is required");
      return;
    }
    if (description === "") {
      alert("Description is required");
      return;
    }
    if (quantity <= 0) {
      alert("Quantity is required and should be more than 0");
      return;
    }
    if (minBudget === 0) {
      alert("Minimum Budget is required and cannot be negative");
      return;
    }
    if (maxBudget === 0) {
      alert("Maximum Budget is required and cannot be negative");
      return;
    }
    if (minBudget > maxBudget) {
      alert("Minimum budget cannot be more than Maximum budget");
      return;
    }

    e.preventDefault();
    setProducts([
      ...products,
      { itemName, quantity, maxBudget, minBudget, description },
    ]);
    setItemName("");
    setQuantity(0);
    setMaxBudget(0);
    setMinBudget(0);
    setDescription("");
  };

  const labelClassName = "block mb-2 text-sm font-medium text-gray-900 ";

  return (
    <div className="w-full p-5 bg-gray-100">
      <h3 className="text-xl font-semibold mb-4">Products</h3>
      <form onSubmit={addProduct} className="mb-4 grid md:grid-cols-2 gap-2">
        <div className="flex flex-col">
          <label className={labelClassName}>Item name</label>
          <input
            type="text"
            value={itemName}
            className="mt-2 mb-4 py-2 px-3 w-full border border-gray-300 rounded focus:outline-none focus:border-blue-300"
            onChange={(e) => setItemName(e.target.value)}
            placeholder="Item Name"
            required
          />
        </div>
        <div className="flex flex-col">
          <label className={labelClassName}>Quantity</label>
          <input
            type="number"
            value={quantity}
            className="mt-2 mb-4 py-2 px-3 w-full border border-gray-300 rounded focus:outline-none focus:border-blue-300"
            onChange={(e) => setQuantity(e.target.value)}
            placeholder="Quantity"
            required
          />
        </div>
        <div className="flex flex-col">
          <label className={labelClassName}>Min budget</label>
          <input
            type="number"
            className="mt-2 mb-4 py-2 px-3 w-full border border-gray-300 rounded focus:outline-none focus:border-blue-300"
            value={minBudget}
            onChange={(e) => setMinBudget(e.target.value)}
            placeholder="Min Budget"
            required
          />
        </div>
        <div className="flex flex-col">
          <label className={labelClassName}>Max budget</label>
          <input
            className="mt-2 mb-4 py-2 px-3 w-full border border-gray-300 rounded focus:outline-none focus:border-blue-300"
            type="number"
            value={maxBudget}
            onChange={(e) => setMaxBudget(e.target.value)}
            placeholder="Max Budget"
            required
          />
        </div>
        <div className="flex flex-col col-span-2">
          <label className={labelClassName}>Description</label>
          <textarea
            value={description}
            className="mt-2 mb-4 py-2 px-3 w-full border border-gray-300 rounded focus:outline-none focus:border-blue-300"
            onChange={(e) => setDescription(e.target.value)}
            placeholder="Description"
            required
          />
        </div>
        <div className="flex flex-col col-span-2 items-center justify-center">
          <button
            onClick={addProduct}
            className="bg-red-600 text-white font-semibold py-2 px-8 rounded-lg"
          >
            Add Product
          </button>
        </div>
      </form>

      <ul className="list-decimal list-inside">
        {products?.map((product, index) => (
          <li key={index} className="mb-4">
            <h3 className="font-bold">{product.itemName}</h3>
            <p className="text-sm">
              <span className="font-bold">Quantity:</span> {product.quantity}
            </p>
            <p className="text-sm">
              <span className="font-bold">Budget:</span> {product.minBudget} -{" "}
              {product.maxBudget}
            </p>
            <p className="text-sm">
              <span className="font-bold">Description:</span>{" "}
              {product.description}
            </p>
          </li>
        ))}
      </ul>
    </div>
  );
}

// Product component for adding individual products
const ProductForm = ({ onAddProduct }) => {
  const [itemName, setItemName] = useState("");
  const [description, setDescription] = useState("");
  const [quantity, setQuantity] = useState(0);
  const [minBudget, setMinBudget] = useState("");
  const [maxBudget, setMaxBudget] = useState("");
  const validateFields = () => {
    let errors = [];
    if (itemName === "") {
      errors.push("Item Name is required");
    }
    if (description === "") {
      errors.push("Description is required");
    }
    if (quantity <= 0) {
      errors.push("Quantity is required and should be more than 0");
    }
    if (minBudget === "") {
      errors.push("Minimum Budget is required and cannot be negative");
    }
    if (maxBudget === "") {
      errors.push("Maximum Budget is required and cannot be negative");
    }

    if (minBudget !== "" && maxBudget !== "" && minBudget > maxBudget) {
      errors.push("Minimum budget cannot be more than Maximum budget");
    }
    return errors;
  };
  const handleAddProduct = () => {
    // validate fields
    if (validateFields().length > 0) {
      alert(validateFields()[0]);
      return;
    }
    const product = {
      itemName,
      description,
      quantity,
      minBudget,
      maxBudget,
    };
    onAddProduct(product);
    // Clear fields after adding product
    setItemName("");
    setDescription("");
    setQuantity(0);
    setMinBudget("");
    setMaxBudget("");
  };

  return (
    <div>
      <input
        type="text"
        placeholder="Item name"
        value={itemName}
        onChange={(e) => setItemName(e.target.value)}
      />
      <input
        type="text"
        placeholder="Description"
        value={description}
        onChange={(e) => setDescription(e.target.value)}
      />
      <input
        type="number"
        placeholder="Quantity"
        value={quantity}
        onChange={(e) => setQuantity(Number(e.target.value))}
      />
      <input
        type="text"
        placeholder="Minimum budget"
        value={minBudget}
        onChange={(e) => setMinBudget(e.target.value)}
      />
      <input
        type="text"
        placeholder="Maximum budget"
        value={maxBudget}
        onChange={(e) => setMaxBudget(e.target.value)}
      />
      <button
        onClick={(e) => {
          e.preventDefault();
          handleAddProduct();
        }}
      >
        Add Product
      </button>
    </div>
  );
};
const StatusIndicator = ({
  status,
  error,
  close,
}: {
  status: "idle" | "loading" | "success" | "error";
  error: any;
  close: any;
}) => {
  const getIndicator = () => {
    switch (status) {
      case "idle":
        return null;
      case "loading":
        return <CircularProgress />;
      case "success":
        return <AiOutlineCheck />;
      case "error":
        return <AiOutlineExclamation />;
      default:
        return null;
    }
  };

  if (status === "idle") return null;

  return (
    <div
      style={{
        position: "fixed",
        bottom: "5%", // can be changed to 'top' to display on the top of screen
        left: "5%",
        width: "90%", // adjust as needed
        padding: 15,
        backgroundColor: "#f9f9f9",
        border: "1px solid #ccc",
        boxShadow: "2px 2px 5px rgba(0,0,0,0.3)",
        zIndex: 100,
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <div style={{ display: "flex", alignItems: "center" }}>
        {getIndicator()}
        <span style={{ marginLeft: 10 }}>
          {status === "error"
            ? error
            : status === "loading"
            ? "Submitting request..."
            : status === "success"
            ? "Request submitted successfully."
            : null}
        </span>
      </div>
      <button onClick={close} style={{ background: "none", border: "none" }}>
        <AiOutlineClose />
      </button>
    </div>
  );
};
const RFQPage = () => {
  const [products, setProducts] = useState([]);
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [location, setLocation] = useState("");

  const [file, setFile] = useState(null); // File input [type: file]
  const [status, setStatus] = useState<
    "idle" | "loading" | "success" | "error"
  >("idle");
  const [error, setError] = useState<any>(null);
  const validateInputs = () => {
    let errors = [];
    if (fullName === "") {
      errors.push("Full Name is required");
    }
    if (
      email === "" ||
      !/^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/.test(email)
    ) {
      errors.push("Enter a valid Email address");
    }
    if (phone === "" || phone.length < 10) {
      errors.push(
        "Phone number is required and should have at least 10 digits"
      );
    }
    if (location === "") {
      errors.push("Location is required");
    }

    return errors;
  };
  const handleSubmit = async (event: any) => {
    event.preventDefault();

    const errors = validateInputs();
    if (errors.length > 0) {
      setError(errors.join(", "));
      setStatus("error");
      return;
    }
    setStatus("loading");
    setError(null);

    try {
      // Submit form to your API

      const formData = new FormData();
      formData.append("name", fullName);
      formData.append("email", email);
      formData.append("phone", phone);
      formData.append("location", location);
      formData.append("items", JSON.stringify(products));
      if (file) {
        formData.append("file", file);
      }

      const response = await axios.post(
        urls.quotations.anonymous_rfq,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      console.log(response.data); // Handle the response data accordingly
      setStatus("success");
      setError("");
      // Clear the form fields after successful submission
      setFullName("");
      setEmail("");
      setPhone("");
      setLocation("");
      setProducts([]);

      setFile(null);
    } catch (error: any) {
      console.error(
        "An error occurred. Please try again later.",
        error.response.data
      );
      setError("Something went wrong while submitting form");
      setStatus("error");
    }
  };

  return (
    <div className="container mx-auto my-12 p-8">
      <h1 className="text-2xl font-semibold mb-6">
        Let us know what products you are looking for
      </h1>
      <p className="text-gray-600 mb-8">
        Fill the form below to request for a quotation. Suitable Vendors will
        get back to you.
      </p>
      <form onSubmit={handleSubmit} method="POST" autoComplete="on">
        <div className="grid md:grid-cols-2 gap-4 mb-4">
          <input
            className="border-2 border-gray-200 rounded-lg p-4"
            type="text"
            placeholder="Full name"
            name="full_name"
            onChange={(e) => setFullName(e.target.value)}
            value={fullName}
            required
          />
          <input
            className="border-2 border-gray-200 rounded-lg p-4"
            type="email"
            placeholder="Email address"
            name="email"
            onChange={(e) => setEmail(e.target.value)}
            value={email}
            required
          />
          <input
            className="border-2 border-gray-200 rounded-lg p-4"
            type="tel"
            required
            placeholder="Phone number"
            name="phone"
            onChange={(e) => setPhone(e.target.value)}
            value={phone}
          />
          <input
            className="border-2 border-gray-200 rounded-lg p-4"
            type="text"
            required
            placeholder="Location"
            name="location"
            onChange={(e) => setLocation(e.target.value)}
            value={location}
          />
          <div className="col-span-2">
            <ProductsSection products={products} setProducts={setProducts} />
          </div>
        </div>

        {/* <div className="flex items-center mb-4">
          <label className="border-2 border-gray-200 rounded-lg p-4 flex items-center justify-center cursor-pointer">
            Image/PDF
            <input
              className="hidden"
              type="file"
              name="file"
              onChange={(e) => setFile(e.target.files[0])}
            />
          </label>
        </div> */}
        <div className="flex items-center justify-between mb-8">
          {/* <button className="bg-red-500 text-white font-semibold py-2 px-6 rounded-full">
    + Add new item
    </button> */}
          <button
            type="submit"
            className="bg-red-600 text-white font-semibold py-2 px-8 rounded-lg"
          >
            Submit
          </button>
        </div>
      </form>
      <StatusIndicator
        close={() => setStatus("idle")}
        status={status}
        error={error}
      />
    </div>
  );
};

export default RFQPage;
