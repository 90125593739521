import React, { useState } from "react";
import { useVendors } from "../../hooks/useVendors";
import VendorView from "../../components/VendorView";
import { Input } from "@/components/ui/input";
import { Skeleton } from "@/components/ui/skeleton";
import { Alert, AlertDescription, AlertTitle } from "@/components/ui/alert";

const VendorsPage = () => {
  const { vendors, status } = useVendors();
  const [searchTerm, setSearchTerm] = useState("");

  const filteredVendors = vendors.filter((vendor) =>
    vendor.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const otherVendors = filteredVendors;

  return (
    <div className="container mx-auto px-4 py-8">
      <Input
        type="text"
        placeholder="Search Vendors"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        className="mb-6"
      />

      {status === "loading" && (
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
          {[...Array(8)].map((_, index) => (
            <Skeleton key={index} className="h-64 w-full" />
          ))}
        </div>
      )}

      {status === "error" && (
        <Alert variant="destructive">
          <AlertTitle>Error</AlertTitle>
          <AlertDescription>
            There was an error loading vendors. Please try again later.
          </AlertDescription>
        </Alert>
      )}

      {status === "success" && (
        <>
          {otherVendors.length > 0 && (
            <div>
              <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
                {otherVendors.map((vendor) => (
                  <VendorView
                    key={vendor.id}
                    vendor={vendor}
                    isLatest={false}
                  />
                ))}
              </div>
            </div>
          )}

          {filteredVendors.length === 0 && (
            <Alert>
              <AlertTitle>No Results</AlertTitle>
              <AlertDescription>
                No vendors found matching your search criteria.
              </AlertDescription>
            </Alert>
          )}
        </>
      )}
    </div>
  );
};

export default VendorsPage;
