import React, { useEffect, useState } from "react";
import { IUser } from "@/types/user";
import { useAppSelector } from "../../app/store";
import axios from "axios";
import urls from "../../constants/urls";
import {
  Box,
  Button,
  CircularProgress,
  Modal,
  Typography,
} from "@mui/material";
import { set } from "lodash";
import { OrderGroup } from "@/types/product";
// export type OrderProductModel = {
//   id: number;
//   product: IProduct;
//   quantity: number;
// };

// export type OrderModel = {
//   id: number;
//   user: ICurrentUser;
//   vendor: Vendor;
//   products: OrderProductModel[];
//   date: string;
//   address: string | null;
//   group: OrderGroup | null;
//   has_made_payment_request: boolean;
//   status: "pending" | "paid" | "failed";
//   vendor_paid: boolean;
// };

// export type OrderGroup = {
//   id: number;
//   user: ICurrentUser;
//   amount: number;
//   order_payment_status: "pending" | "completed" | "failed";
//   date: string;
//   status: "pending" | "completed" | "failed";
//   group_orders: OrderModel[];
// };

// type Order = {
//   id: number;
//   user: IUser;
//   amount: number;
//   order_payment_status: "pending" | "completed" | "failed";
//   date: string;
//   status: "pending" | "completed" | "failed";
// };

const UserProfile = () => {
  const user = useAppSelector((state) => state?.user.user);
  // console.log(user);

  // fetch user orders
  const [orders, setOrders] = useState<OrderGroup[]>([]);
  const [status, setStatus] = useState<"loading" | "success" | "error">(
    "loading"
  ); // all, shipped, pending, cancelled
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState<OrderGroup | null>(null);

  useEffect(() => {
    const fetchOrders = async () => {
      setStatus("loading");
      try {
        const response = await axios.get(urls.orders.order_groups, {
          headers: {
            Authorization: `Token ${user?.token}`,
          },
        });
        setStatus("success");
        setOrders(response.data);
      } catch (error) {
        setStatus("error");
        console.error("Error fetching orders", error);
      }
    };
    fetchOrders();
  }, []);

  // calculate the count of all the products in the order
  const calculateOrderItems = (order: OrderGroup) => {
    let count = 0;
    order.group_orders.forEach((order) => {
      count += order.products.length;
    });
    return count;
  };

  return (
    <div className="container mx-auto bg-white p-6 shadow-lg min-h-screen">
      <div className="flex items-center mb-6">
        <img
          src={user?.icon || "/avatar-placeholder.webp"}
          alt="User"
          className="rounded-full w-16 h-16 mr-4"
        />
        <div>
          <h1 className="text-2xl font-semibold">
            {user?.first_name} {user?.last_name}
          </h1>
          <p className="text-gray-600">{user?.email}</p>
          <p className="text-gray-600">
            {/* Joined on {new Date(user?.created_at).toDateString()} */}
          </p>
        </div>
        {/* if user is vendor, show a link icon with the text - Go to vendor dashboard */}
        <a href="https://vendor.m-jenzi.com" className="ml-auto">
          {user?.is_vendor && (
            <div className="flex items-center">
              <span>Go to vendor dashboard</span>
            </div>
          )}
        </a>
      </div>
      <div className="flex gap-4 mb-6">
        {/* Navigation Links */}
        {/* <button className="border-b-2 border-transparent hover:border-blue-500">
          Account settings
        </button> */}
        {/* <button className="border-b-2 border-transparent hover:border-blue-500">
          Manage password
        </button> */}
        {/* <button className="border-b-2 border-transparent hover:border-blue-500">
          Order history
        </button> */}
        {/* <button className="border-b-2 border-transparent hover:border-blue-500">
          Payment methods
        </button>
        <button className="border-b-2 border-transparent hover:border-blue-500">
          Address
        </button>
        <button className="border-b-2 border-transparent hover:border-blue-500">
          Notification
        </button> */}
      </div>
      <div>
        <h2 className="text-xl font-semibold mb-2">Order history</h2>
        <p className="mb-6">Here you can manage your orders</p>

        {/* Search and Filters */}
        {/* <div className="flex mb-4">
          <input
            className="border-2 border-gray-200 rounded-lg p-2 mr-2 flex-grow"
            placeholder="Search for Order ID or Product"
          />
          <button className="border-2 border-gray-200 rounded-lg p-2">
            Filters
          </button>
        </div> */}
        <Modal
          open={modalOpen}
          onClose={() => {
            setModalOpen(false);
            setSelectedOrder(null);
          }}
        >
          <Box
            style={{
              backgroundColor: "white",
              padding: "20px",
              margin: "auto",
              marginTop: "50px",
              width: "70%",
            }}
          >
            <Typography variant="h6" gutterBottom>
              Order ID: {selectedOrder?.id}
            </Typography>
            <Typography variant="body1" gutterBottom>
              Amount: KES {selectedOrder?.amount}
            </Typography>
            <Typography variant="body1" gutterBottom>
              Status: {selectedOrder?.status}
            </Typography>
            <Typography variant="body1" gutterBottom>
              Payment Status: {selectedOrder?.order_payment_status}
            </Typography>
            <Typography variant="body1">
              Date: {new Date(selectedOrder?.date || "").toDateString()}
            </Typography>
            {selectedOrder?.group_orders.map((order) => (
              <Box
                border={1}
                borderColor="divider"
                borderRadius={2}
                padding={2}
                margin={1}
              >
                {order.products.map((product) => (
                  <Box display="flex" justifyContent="space-between" margin={1}>
                    <img
                      src={product.product.icon}
                      alt={product.product.name}
                      width={50}
                      height={50}
                    />
                    <Typography variant="body2">
                      {product.product.name}
                    </Typography>
                    <Typography variant="body2" fontWeight={"bold"}>
                      Qty: {product.quantity}
                    </Typography>
                  </Box>
                ))}
              </Box>
            ))}
            <Button
              color="primary"
              variant="contained"
              onClick={() => setModalOpen(false)}
            >
              Ok
            </Button>
          </Box>
        </Modal>
        {/* Order Table */}
        <div className="overflow-hidden border-b border-gray-200 sm:rounded-lg">
          <table className="min-w-full">
            <thead className="bg-gray-50">
              <tr>
                <th
                  scope="col"
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  Order ID
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  Date
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  Items
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  Total amount
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  Status
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  Payment
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  View
                </th>
              </tr>
            </thead>
            <tbody>
              {status === "loading" ? (
                <CircularProgress />
              ) : status === "error" ? (
                <span className="text-red-500">Error fetching orders</span>
              ) : null}
              {orders.map((order) => (
                <tr className="bg-white">
                  <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                    {order.id}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    {new Date(order.date).toDateString()}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    {calculateOrderItems(order)}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    KES {order.amount}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    {
                      {
                        pending: (
                          <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-yellow-100 text-yellow-800">
                            Pending
                          </span>
                        ),
                        completed: (
                          <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                            Completed
                          </span>
                        ),
                        failed: (
                          <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-red-100 text-red-800">
                            Failed
                          </span>
                        ),
                      }[order.status]
                    }
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm font-medium">
                    {order.order_payment_status === "pending" ? (
                      <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-yellow-100 text-yellow-800">
                        Pending
                      </span>
                    ) : order.order_payment_status === "completed" ? (
                      <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                        Completed
                      </span>
                    ) : (
                      <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-red-100 text-red-800">
                        Failed
                      </span>
                    )}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm font-medium">
                    <button
                      className="text-blue-500"
                      onClick={() => {
                        setSelectedOrder(order);
                        setModalOpen(true);
                      }}
                    >
                      View
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default UserProfile;
