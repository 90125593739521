const baseUrl = "https://m-jenzi.azurewebsites.net/api/v1/";
// const baseUrl = "http://localhost:8000/api/v1/";

const urls = {
  auth: {
    login: baseUrl + "auth/login/",
    register: baseUrl + "auth/register/",
    logout: baseUrl + "auth/logout/",
    me: baseUrl + "auth/me",
    verify_token: baseUrl + "auth/verify-token/",
    password_reset_request: baseUrl + "auth/password-reset-request/",
    perform_reset: baseUrl + "auth/perform-reset/",
    verify_otp: baseUrl + "auth/verify-otp/",
  },
  items: {
    all: baseUrl + "items/",
    detail: (item_id: any) => baseUrl + "items/" + item_id + "/",
    categories: baseUrl + "items/categories/",
    highlight_categories_with_products:
      baseUrl + "items/highlight-categories-with-products/",
    most_purchased_categories: baseUrl + "items/most-purchased-categories/",
    item_reviews: (item_id: any) => baseUrl + "items/item-reviews/" + item_id,
    add_review: baseUrl + "items/reviews/",
    adverts: baseUrl + "items/website-adverts/",
    advertDetail: (index: number) =>
      baseUrl + "items/website-adverts/" + index + "/",
  },

  orders: {
    all: baseUrl + "/orders",
    order_groups: baseUrl + "orders/my-order-groups/",
    detail: (order_id: any) => baseUrl + "orders/" + order_id + "/",
    create_orders: baseUrl + "orders/create-orders/",
    order_stk_push: baseUrl + "orders/order-stk-push/",
    create_cart_orders: baseUrl + "orders/create-cart-orders/",
    check_order_payment_status: baseUrl + "orders/check-order-payment-status/",
  },
  cart: {
    base: baseUrl + "cart/",
    cart_items: baseUrl + "cart/cart-items/",
    add_cart_items: baseUrl + "cart/add-to-cart/",
    update_cart_item_quantity: baseUrl + "cart/update-cart-item-quantity/",
    remove_cart_item: (id: number) =>
      baseUrl + "cart/remove-from-cart/" + id + "/",
  },
  vendors: {
    all: baseUrl + "vendors/",
    detail: (vendor_id: any) => baseUrl + "vendors/" + vendor_id + "/",
  },
  quotations: {
    anonymous_rfq: baseUrl + "quotations/anonymous-request-for-quotation/",
  },
  blogs: {
    all: baseUrl + "blogs/",
    detail: (slug: string) => baseUrl + "blogs/" + slug + "/",
  },
  core: {
    contact: baseUrl + "core/contact/",
  },
};

export default urls;
