"use client";
import { Routes, Route, BrowserRouter, Navigate } from "react-router-dom";

// Components
import {
  Footer,
  Loading,
  Navbar,
  Newsletter,
  ScrollToTop,
  Subscribe,
} from "./components";

import {
  Home,
  Auth,
  Products,
  ProductDetail,
  Cart,
  FavoriteProducts,
} from "./pages";

import { Box } from "@mui/material";
import { useAppSelector } from "./app/store";
import CategoriesPage from "./pages/Categories";
import VendorsPage from "./pages/Vendors";
import VendorDetailPage from "./pages/VendorDetail";
import VendorProducts from "./pages/VendorProducts";
import AboutUs from "./pages/About";
import ContactPage from "./pages/Contact";
import BlogPage from "./pages/Blog";
import BlogDetail from "./pages/BlogDetail";
import RequestForQuotation from "./pages/RFQ";
import ResourceCenter from "./pages/ResourceCenter";
// @ts-ignore
import AOS from "aos"; // @ts-ignore

import "aos/dist/aos.css";
import { useEffect } from "react";
import UserProfile from "./pages/Profile";

function App() {
  const state = useAppSelector((state) => state);
  console.log("state , state", state);

  useEffect(() => {
    AOS.init({
      duration: 2000,
    });
  }, []);
  return (
    <BrowserRouter>
      <Box
        style={{
          backgroundColor: "#ffffff",
          // backgroundImage: `url("data:image/svg+xml,%3Csvg width='36' height='36' viewBox='0 0 36 36' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M36 0H0v36h36V0zM15.126 2H2v13.126c.367.094.714.24 1.032.428L15.554 3.032c-.188-.318-.334-.665-.428-1.032zM18 4.874V18H4.874c-.094-.367-.24-.714-.428-1.032L16.968 4.446c.318.188.665.334 1.032.428zM22.874 2h11.712L20 16.586V4.874c1.406-.362 2.512-1.468 2.874-2.874zm10.252 18H20v13.126c.367.094.714.24 1.032.428l12.522-12.522c-.188-.318-.334-.665-.428-1.032zM36 22.874V36H22.874c-.094-.367-.24-.714-.428-1.032l12.522-12.522c.318.188.665.334 1.032.428zm0-7.748V3.414L21.414 18h11.712c.362-1.406 1.468-2.512 2.874-2.874zm-18 18V21.414L3.414 36h11.712c.362-1.406 1.468-2.512 2.874-2.874zM4.874 20h11.712L2 34.586V22.874c1.406-.362 2.512-1.468 2.874-2.874z' fill='%23ffe2e2' fill-opacity='0.26' fill-rule='evenodd'/%3E%3C/svg%3E")`,
        }}
        sx={{ maxWidth: "1600px", marginLeft: "auto", marginRight: "auto" }}
      >
        <Navbar />
        <Loading />
        <ScrollToTop />
        <Subscribe />
        <></>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/products/:primaryCategory" element={<Products />} />

          <Route path="/shop" element={<Products />} />
          <Route path="/contact" element={<ContactPage />} />
          <Route path="/product-detail" element={<ProductDetail />} />
          <Route path="/auth/:key" element={<Auth />} />
          <Route path="/cart" element={<Cart />} />
          <Route path="/favorite-products" element={<FavoriteProducts />} />
          <Route path="/categories" element={<CategoriesPage />} />
          <Route path="*" element={<Navigate to="/" />} />
          <Route path="/vendors" element={<VendorsPage />} />
          <Route path="/vendors/:vendor_slug" element={<VendorDetailPage />} />
          <Route
            path="/vendors/:vendor_slug/products"
            element={<VendorProducts />}
          />
          <Route path="/about-us" element={<AboutUs />} />
          <Route path="/blog" element={<BlogPage />} />
          <Route path="/blog/:slug" element={<BlogDetail />} />
          <Route path="/rfq" element={<RequestForQuotation />} />
          <Route path="/resource-center" element={<ResourceCenter />} />
          <Route path="/profile" element={<UserProfile />} />
        </Routes>
        {/* <Newsletter /> */}
        <Footer />
      </Box>
    </BrowserRouter>
  );
}

export default App;
