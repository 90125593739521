import React from "react";

const AboutUs = () => {
  const partners = [
    {
      name: "Paybills.Africa",
      identifier: "paybills.africa",
      description:
        "As collaborators in the construction sector, PayBills.Africa brings digital payment expertise to the table, revolutionizing financial transactions. Our cutting-edge solutions contribute to a modernized payment ecosystem, fostering a more agile and forward-thinking approach for your construction projects.",
    },
    {
      name: "MANPRO: PROJECT MANAGEMENT",
      identifier: "manpro",
      description:
        "It's the little things that make construction work hectic. Manpro gives you templates to generate work plans, track materials, manage labour & equipment schedules in minutes. The software also takes care of the tougher crucial tasks by allowing you to allocate & track resources in real time, whether you are at the site or supervising remotely.",
    },
  ];

  return (
    <div className="flex flex-col items-center justify-center bg-transparent p-6 lg:p-10">
      <div className="grid grid-cols-1 lg:grid-cols-2 py-10 lg:px-20 gap-6 min-h-[70vh] border-b-2 border-b-gray-400">
        <h1 className="text-3xl lg:text-4xl font-bold">
          SIMPLIFYING
          <br /> <span className="text-red-600">CONSTRUCTION</span>
          <br />
          IN KENYA.
        </h1>
        <p className="text-gray-600">
          Through its Business to Business Marketplace, M-Jenzi seamlessly
          integrates the construction materials supply chain through on one hand
          providing an integrated market for manufacturers and suppliers of
          construction materials and on the other hand providing a a one stop,
          reliable source of all construction materials for hardware stores and
          construction companies
        </p>
      </div>
      <div className="bg-white rounded p-4 lg:p-6 m-4 w-full lg:max-w-5xl">
        <h1 className="text-xl lg:text-2xl font-bold text-red-600">
          What else do we do?
        </h1>
        <div className="mb-4">
          <p className="text-gray-600">
            M-jenzi serves as the vital link connecting construction and
            construction and home improvement product and service providers with
            buyers seeking these products and services. Our platform has
            revolutionized the way customers explore and acquire items,
            providing a convenient ONE STOP SHOP accessible from laptops,
            desktops, or phones.
          </p>
        </div>
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 border-b-2 border-b-gray-400 py-20">
          <h1 className="text-3xl lg:text-4xl font-bold">
            Your Project, <br />
            <span className="text-red-600">Under Your Control</span>
          </h1>
          <p className="text-gray-600">
            {" "}
            Enjoy peace of mind knowing that all the essentials for your
            project, including construction materials, furniture, energy
            solutions, water and plumbing supplies, electrical appliances, and
            electrician services, can be securely purchased through our
            platform. With M-jenzi.com, you are in complete control of your
            purchases and their timely delivery.
          </p>
        </div>
        <div className="mb-4 py-20">
          <h1 className="text-xl lg:text-2xl font-semibold">
            Our
            <span className="text-red-600"> Partners</span>
          </h1>
          <p className="text-gray-600">
            We are not alone in our mission to revolutionize the construction
            arena
          </p>
          {partners.map((partner, ind) => (
            <div
              key={ind}
              className={"py-4  rounded  mt-10"}
              id={partner.identifier}
            >
              <h2 className="text-xl font-bold">{partner.name}</h2>
              <div className="h-1 w-20 rounded-full bg-red-600"></div>
              <p className="mt-5 text-gray-600">{partner.description}</p>
            </div>
          ))}
        </div>
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 border-t-2 border-t-gray-200 py-10">
          <div className="bg-white rounded  m-4 border-r-2 border-r-red-500">
            <h1 className="text-xl lg:text-2xl font-semibold">Our Mission</h1>
            <p className="text-gray-600">
              M-jenzi.com is committed to providing a comprehensive platform
              that simplifies the process of acquiring construction and home
              improvement products and services. We aim to streamline the
              procurement process, ensuring that customers can access a wide
              range of products and services from the comfort of their homes or
              offices.
            </p>
          </div>
          <div className="bg-white rounded  m-4 w-full">
            <h1 className="text-xl lg:text-2xl font-semibold">Our Vision</h1>
            <p className="text-gray-600">
              Our vision is to become the leading online marketplace for
              construction and home improvement products and services in Kenya.
              We are committed to providing a seamless and secure platform that
              connects buyers and sellers, ensuring that customers can access
              quality products and services at competitive prices.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
