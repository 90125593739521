import {
  Box,
  Button,
  CircularProgress,
  TextField,
  Typography,
} from "@mui/material";
import { Pagination } from "@mui/material";
import ProductCard from "../ProductCard";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "../../app/store";
import { useEffect, useState } from "react";
import { fetchProductsByCategories } from "../../features/products/productsSlice";
import useProducts from "../../hooks/useProducts";
import { useParams, useSearchParams } from "react-router-dom";
import SearchIcon from "@mui/icons-material/Search";
import { Loading } from "..";
import { Skeleton } from "@mui/material";

const ProductList = () => {
  const {
    categorySearchParameters,
    user,
    currentUser: { currentUser },
    products: { productsByCategory },
  } = useSelector((state: RootState) => state);
  const cparams = useParams();

  const category_slug = cparams.primaryCategory;

  const dispatch = useAppDispatch();

  let [searchParams, setSearchParams] = useSearchParams();

  const UrlSearchQuery = searchParams.get("search") || "";

  const [searchQuery, setSearchQuery] = useState(UrlSearchQuery);

  const {
    products,
    currentPage,
    totalPages,
    fetchNextPage,
    productsStatus,
    fetchPage,
    fetchPreviousPage,
  } = useProducts({
    search: searchQuery,
    categorySlug: category_slug,
  });

  const handlePageChange = (event, newPage) => {
    fetchPage(newPage);
  };

  const handleSearch = () => {
    // The serialize function here would be responsible for
    // creating an object of { key: value } pairs from the
    // fields in the form that make up the query.
    let params = {
      search: searchQuery,
      page: currentPage,
    };

    const queryString = Object.entries(params)
      .map(
        ([key, value]) =>
          `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
      )
      .join("&");

    setSearchParams(queryString);
  };

  useEffect(() => {}, []);
  if (productsStatus === "loading") {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          flexWrap: "wrap",
          width: "100%",
          maxWidth: "944px",
          margin: "0 auto",
          gap: "16px",
        }}
      >
        {/* Render skeleton components for loading state */}
        <Skeleton variant="rectangular" height={200} width={300} />
        <Skeleton variant="rectangular" height={200} width={300} />
        <Skeleton variant="rectangular" height={200} width={300} />
      </Box>
    );
  }
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        flexWrap: "wrap",
        width: "100%",
        maxWidth: "944px",
        margin: "0 auto",
      }}
    >
      <form
        onSubmit={(e) => {
          e.preventDefault();
          handleSearch();
        }}
        className="flex flex-row items-center justify-center mb-10"
      >
        {/* Search Bar */}
        <TextField
          label="Search"
          variant="outlined"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          sx={{
            width: "calc(100% - 48px)", // Adjust the width to leave space for the button
            maxWidth: "400px", // Adjust the max-width as needed
            marginRight: "8px", // Add right margin for spacing
            "& input": {
              fontSize: "16px", // Adjust font size as needed
              padding: "12px", // Adjust padding as needed
            },
          }}
        />

        {/* Search Button */}
        <Button
          variant="contained"
          size="small" // Adjust the size as needed
          startIcon={<SearchIcon />} // Use the Search icon
          sx={{
            height: "40px", // Adjust the height as needed
            minWidth: "40px", // Adjust the width as needed
          }}
          onClick={handleSearch}
        />
      </form>

      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "center",
          columnGap: "16px",
          rowGap: "35px",
          minHeight: "500px",
          width: "100%",
          maxWidth: "944px",
        }}
      >
        {productsStatus == "loading" ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              marginTop: "20px",
            }}
          >
            <CircularProgress color="primary" />
          </Box>
        ) : productsStatus == "error" ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              marginTop: "20px",
            }}
          >
            <p>There was an error loading products</p>
          </Box>
        ) : products?.length == 0 ? (
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <p>No products found</p>
          </Box>
        ) : (
          <></>
        )}
        {products?.length &&
          products.map((product, index) => (
            <ProductCard product={product} key={index} />
          ))}
      </Box>

      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          marginTop: "20px",
        }}
      >
        {/* Pagination */}
        {products?.length > 0 && (
          <Pagination
            count={totalPages}
            page={currentPage}
            onChange={handlePageChange}
            variant="outlined"
            shape="rounded"
          />
        )}
      </Box>
    </Box>
  );
};

export default ProductList;
