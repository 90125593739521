import axios from "axios";
import { SetStateAction } from "react";
import urls from "../constants/urls";
import {
  setCurrentUser,
  setCurrentUserLoading,
} from "../features/currentUser/currentUserSlice";
import { setUser, setUserLoading } from "../features/user/userSlice";
import { keys } from "lodash";
import storagekeys from "../constants/storagekeys";

export const loginWithEmail = async (
  values: { email: string; password: string },
  states: {
    setLoading: React.Dispatch<SetStateAction<boolean>>;
    navigate: any;
    setErrorMessage: React.Dispatch<SetStateAction<string>>;
    dispatch: any;
  }
) => {
  const { setLoading, navigate, setErrorMessage, dispatch } = states;
  setLoading(true);
  dispatch(setCurrentUserLoading(true));

  try {
    const response = await axios.post(urls.auth.login, {
      email: values.email,
      password: values.password,
    });

    if (response.status === 200) {
      // Handle successful login, you can navigate or set user state here.
      const token = response.data.token; // Assuming the API returns a token
      const user = response.data.user; // Assuming the API returns user data

      // Store the token in a secure place (e.g., in localStorage or cookies)
      localStorage.setItem(
        storagekeys.user,
        JSON.stringify({
          token,
          ...user,
        })
      );

      // Set user data in your application's state
      dispatch(setCurrentUser({ token, ...user }));
      dispatch(setCurrentUserLoading(false));
      dispatch(setUserLoading(false));
      dispatch(setUser({ token, ...user }));
      navigate("/");
    }
  } catch (error: any) {
    dispatch(setCurrentUserLoading(false));
    // Handle login errors and set the error message
    if (error.response) {
      setErrorMessage(error.response.data.message);
      alert(error.response.data.message);
    } else {
      setErrorMessage("An error occurred during login.");
      alert("An error occurred during login.");
    }
  }
  setLoading(false);
};

export const logout = (states: {
  setLoading: React.Dispatch<SetStateAction<boolean>>;
  navigate: any;
  setErrorMessage: React.Dispatch<SetStateAction<string>>;
  dispatch: any;
}) => {
  const { setLoading, navigate, setErrorMessage, dispatch } = states;
  setLoading(true);

  try {
    // Remove the user token from local storage
    localStorage.removeItem(storagekeys.user);

    // Clear the user information from the application state
    dispatch(setCurrentUser(null));
    dispatch(setCurrentUserLoading(false));
    dispatch(setUserLoading(false));
    dispatch(setUser(null));

    // Navigate to the login or home page (according to your app's flow)
    navigate("/login");

    // If you want to actually log out from a session managed on the backend,
    // you would also need to add a call to your backend here like this:
    // await axios.post(urls.auth.logout);
  } catch (error: any) {
    dispatch(setCurrentUserLoading(false));
    // Handle logout errors and set the error message
    if (error.response) {
      setErrorMessage(error.response.data.message);
    } else {
      setErrorMessage("An error occurred during logout.");
    }
  }
  setLoading(false);
};
