import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../app/store";
import urls from "../constants/urls";
import axios from "axios";
import { setUserProductsInCart } from "../features/user/userSlice";

const CART_API_URL = urls.cart.base;
const UPDATE_CART_ITEM_API_URL = urls.cart.update_cart_item_quantity;

// Function to update the amount in the cart
const updateItem = async ({
  productId,
  amount,
}: {
  productId: number;
  amount: number;
}) => {
  try {
    await axios.put(
      UPDATE_CART_ITEM_API_URL,
      {
        product: productId,
        quantity: amount,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${user.user?.token}`,
        },
      }
    );
  } catch (error) {
    console.error("Error updating cart item:", error);
  }
};

export const useCart = () => {
  const dispatch = useAppDispatch();
  const user = useAppSelector((state) => state.user);

  const [cartStatus, setCartStatus] = useState<
    "idle" | "loading" | "done" | "error"
  >("idle");

  // Function to fetch cart items from the backend
  const fetchCart = async () => {
    setCartStatus("loading");

    try {
      const cartResp = await axios.get(CART_API_URL, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${user.user?.token}`,
        },
      });

      setCartStatus("done");

      console.log("Cart response:", cartResp.data);

      dispatch(setUserProductsInCart(cartResp.data.cart_items));
    } catch (error) {
      console.error("Error fetching cart:", error);
      setCartStatus("error");
    }
  };

  // Function to refresh cart
  const refreshCart = () => {
    fetchCart();
  };

  // Fetch cart when user changes
  useEffect(() => {
    if (user?.user?.token) {
      fetchCart();
    }
  }, []);

  return {
    cartStatus,
    fetchCart,
    refreshCart,
    updateItem,
  };
};
