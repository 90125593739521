import { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios"; // Import Axios for making HTTP requests
import urls from "../../constants/urls"; // Import API URLS
import {
  Stack,
  Box,
  Typography,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Button,
  InputAdornment,
} from "@mui/material";

import { Visibility, VisibilityOff } from "@mui/icons-material";

import theme from "../../theme";

import { sharableInputLabelStyles } from "../../ui/sharableStyles";

// formik
import * as yup from "yup";
import { Formik, Form, Field } from "formik";
import { TextField } from "formik-mui";
import {
  setCurrentUser,
  setCurrentUserLoading,
} from "../../features/currentUser/currentUserSlice";
import { loginWithEmail } from "../../helpers/loginUser";
import { useDispatch } from "react-redux";

const validationSchema = yup.object({
  email: yup
    .string()
    .email("Enter a valid email")
    .required("Email is required"),
  password: yup
    .string()
    .min(8, "Password should be of minimum 8 characters length")
    .required("Password is required"),
});

const initialValues = { email: "", password: "" };

const Login = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [isPasswordShown, setIsPasswordShown] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const dispatch = useDispatch();
  const handleClickPasswordIcon = () => {
    setIsPasswordShown((previousState) => !previousState);
  };

  const handleClickForgotPassword = () => {
    navigate({ pathname: "/auth/forgot-password" });
  };

  const handleClickSignUp = () => {
    navigate({ pathname: "/auth/register" });
  };

  const handleChangeInput = () => {
    setErrorMessage("");
  };

  return (
    <Stack
      alignItems="center"
      sx={{
        padding: "16px",
        "& form": {
          display: "flex",
          flexDirection: "column",
          rowGap: "15px",
          width: "100%",
          maxWidth: "448px",
        },
        "& .MuiOutlinedInput-root": {
          borderRadius: "56px",
          borderColor: "#D8D8D8",
        },
      }}
    >
      <Typography
        sx={{
          fontSize: "22px",
          fontWeight: theme.fontWeight.semiBold,
          marginBottom: "24px",
        }}
      >
        Login
      </Typography>
      <Typography
        sx={{
          color: "#7D7D7D",
          fontSize: "13px",
          fontWeight: theme.fontWeight.regular,
          lineHeight: "25px",
          maxWidth: "358px",
          textAlign: "center",
          marginBottom: "40px",
        }}
      >
        Login with your data that you entered during registration
      </Typography>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          loginWithEmail(values, {
            setLoading,
            setErrorMessage,
            navigate,
            dispatch,
          });
        }}
      >
        {({ errors }) => (
          <Form id="loginForm" noValidate onChange={handleChangeInput}>
            <Field
              disabled={loading}
              component={TextField}
              type="email"
              name="email"
              label="Email"
              sx={{ ...sharableInputLabelStyles }}
            />
            <Field
              disabled={loading}
              component={TextField}
              type={isPasswordShown ? "text" : "password"}
              name="password"
              label="Password"
              InputProps={{
                endAdornment: (
                  <InputAdornment
                    position="end"
                    onClick={handleClickPasswordIcon}
                    sx={{ cursor: "pointer" }}
                  >
                    {isPasswordShown ? <Visibility /> : <VisibilityOff />}
                  </InputAdornment>
                ),
              }}
              sx={{
                ...sharableInputLabelStyles,
                "& .MuiFormHelperText-root": {
                  color: "#d32f2f",
                },
              }}
              helperText={
                errors.password
                  ? errors.password
                  : errorMessage
                  ? errorMessage
                  : ""
              }
            />
            <FormGroup
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                "& label": { width: "max-content", marginRight: "0" },
              }}
            >
              <Field
                component={FormControlLabel}
                control={<Checkbox />}
                label="Keep me signed in"
                sx={{
                  "& span": {
                    fontSize: "13px",
                    fontWeight: theme.fontWeight.regular,
                  },
                }}
              />
              <Typography
                sx={{
                  fontSize: "13px",
                  width: "max-content",
                  fontWeight: theme.fontWeight.regular,
                  color: "#808080",
                  cursor: "pointer",
                }}
                onClick={handleClickForgotPassword}
              >
                Forgot password?
              </Typography>
            </FormGroup>
          </Form>
        )}
      </Formik>
      <Button
        variant="contained"
        form="loginForm"
        type="submit"
        sx={{
          backgroundColor: "#FBB03B",
          borderRadius: "56px",
          display: "flex",
          alignItems: "center",
          fontSize: "13px",
          fontWeight: theme.fontWeight.semiBold,
          marginTop: "20px",
          maxWidth: { xs: "400px", sm: "448px" },
          textAlign: "center",
          textTransform: "none",
          "&:hover": {
            backgroundColor: "#ffb53d",
          },
          height: "56px",
          width: "100%",
        }}
      >
        Sign in
      </Button>
      <Button
        variant="contained"
        href="https://vendor.m-jenzi.com"
        sx={{
          backgroundColor: "#FBB03B",
          borderRadius: "56px",
          display: "flex",
          alignItems: "center",
          fontSize: "13px",
          fontWeight: theme.fontWeight.semiBold,
          marginTop: "20px",
          maxWidth: { xs: "400px", sm: "448px" },
          textAlign: "center",
          textTransform: "none",
          "&:hover": {
            backgroundColor: "#ffb53d",
          },
          height: "56px",
          width: "100%",
        }}
      >
        Login to vendor portal
      </Button>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          columnGap: "30px",
          marginTop: "22px",
        }}
      >
        <Typography
          sx={{ fontSize: "14px", fontWeight: theme.fontWeight.regular }}
        >
          Not a member yet?
        </Typography>
        <Typography
          sx={{ fontSize: "14px", fontWeight: "500", cursor: "pointer" }}
          onClick={handleClickSignUp}
        >
          Sign up
        </Typography>
      </Box>
    </Stack>
  );
};

export default Login;
