import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { Search, ShoppingCart, Heart, User, Menu, X } from "lucide-react";
import { Button } from "@/components/ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import {
  NavigationMenu,
  NavigationMenuContent,
  NavigationMenuItem,
  NavigationMenuLink,
  NavigationMenuList,
  NavigationMenuTrigger,
  navigationMenuTriggerStyle,
} from "@/components/ui/navigation-menu";
import { Badge } from "@/components/ui/badge";
import { useLocation } from "react-router-dom";
import { RootState, useAppDispatch } from "../../app/store";
import MenuItems from "../../constants/categories.json";
import { logout } from "../../helpers/loginUser";
import { motion, AnimatePresence } from "framer-motion";
const MobileNavIcons = () => {
  const navigate = useNavigate();
  const {
    user: { user },
    currentUser: { currentUser },
  } = useSelector((state: RootState) => state);

  const handleClickShoppingCart = () => {
    if (currentUser) {
      navigate({ pathname: "/cart" });
    } else {
      navigate("/auth/login");
    }
  };

  const handleClickFavoriteIcon = () => {
    if (currentUser) {
      navigate({ pathname: "/favorite-products" });
    } else {
      navigate("/auth/login");
    }
  };

  return (
    <div className="flex items-center sm:hidden">
      <Button variant="ghost" size="icon" onClick={() => navigate("/shop")}>
        <Search className="h-5 w-5" />
      </Button>
      <Button variant="ghost" size="icon" onClick={handleClickShoppingCart}>
        <Badge variant="secondary" className="absolute -top-2 -right-2">
          {user?.userProductsInCart?.length || 0}
        </Badge>
        <ShoppingCart className="h-5 w-5" />
      </Button>
      <Button variant="ghost" size="icon" onClick={handleClickFavoriteIcon}>
        <Heart className="h-5 w-5" />
      </Button>
      {/* Add User icon and dropdown menu if needed */}
    </div>
  );
};

const NavigationBar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const navigate = useNavigate();
  const { pathname } = useLocation();
  const {
    user: { user },
    currentUser: { currentUser },
  } = useSelector((state: RootState) => state);

  const [selectedTabIndex, setSelectedTabIndex] = useState<number | boolean>(
    false
  );

  const [isDrawerOpened, setIsDrawerOpened] = useState(false);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const dispatch = useAppDispatch();

  const handleLogout = () => {
    setAnchorEl(null);
    logout({
      dispatch,
      navigate,
      setErrorMessage: () => {},
      setLoading: () => {},
    });
  };

  const handleLogin = () => {
    setAnchorEl(null);
    navigate("/auth/login");
  };

  const handleRegister = () => {
    setAnchorEl(null);
    navigate("/auth/register");
  };

  const handleClickShoppingCart = () => {
    if (currentUser) {
      navigate({ pathname: "/cart" });
    } else {
      navigate("/auth/login");
    }
  };

  const handleClickFavoriteIcon = () => {
    if (currentUser) {
      navigate({ pathname: "/favorite-products" });
    } else {
      navigate("/auth/login");
    }
  };

  const [isSticky, setIsSticky] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      const stickyThreshold = 100;

      setIsSticky(scrollPosition > stickyThreshold);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleLinkClick = () => {
    setIsMobileMenuOpen(false);
  };

  return (
    <nav
      className={`bg-white shadow-md transition-all duration-300 ${
        isSticky ? "fixed top-0 left-0 right-0 z-50" : ""
      }`}
    >
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between h-16 items-center">
          <div className="flex items-center">
            <Link to="/">
              <img
                src={"/m-jenzi-logo.png"}
                alt="M-jenzi Logo"
                className="h-8"
              />
            </Link>
            <div className="hidden sm:ml-6 sm:flex sm:space-x-8">
              <NavigationMenu>
                <NavigationMenuList>
                  {MenuItems.map((item) => (
                    <NavigationMenuItem key={item.name}>
                      {item?.sublinks?.length > 0 ? (
                        <NavigationMenuTrigger>
                          {item.name}
                        </NavigationMenuTrigger>
                      ) : (
                        <Link
                          to={item.link}
                          target={item.external ? "_blank" : ""}
                          onClick={handleLinkClick}
                        >
                          <NavigationMenuLink
                            className={navigationMenuTriggerStyle()}
                          >
                            {item.name}
                          </NavigationMenuLink>
                        </Link>
                      )}
                      {item?.sublinks?.length > 0 && (
                        <NavigationMenuContent>
                          <ul className="grid gap-3 p-4 md:w-[400px] lg:w-[500px] lg:grid-cols-[.75fr_1fr]">
                            {item?.sublinks.map((sublink) => (
                              <li key={sublink.name}>
                                <NavigationMenuLink asChild>
                                  <a
                                    href={sublink.link}
                                    onClick={handleLinkClick}
                                    className="block select-none space-y-1 rounded-md p-3 leading-none no-underline outline-none transition-colors hover:bg-red-500 hover:text-white focus:bg-red-500 focus:text-white"
                                  >
                                    <div className="text-sm font-medium leading-none">
                                      {sublink.name}
                                    </div>
                                  </a>
                                </NavigationMenuLink>
                              </li>
                            ))}
                          </ul>
                        </NavigationMenuContent>
                      )}
                    </NavigationMenuItem>
                  ))}
                </NavigationMenuList>
              </NavigationMenu>
            </div>
          </div>
          <div className="hidden sm:flex sm:items-center">
            <Button
              variant="ghost"
              size="icon"
              onClick={() => navigate("/shop")}
            >
              <Search className="h-5 w-5" />
            </Button>
            <Button
              variant="ghost"
              size="icon"
              className="relative"
              onClick={handleClickShoppingCart}
            >
              <Badge variant="default" className="absolute -top-2 -right-2">
                {user?.userProductsInCart?.length || 0}
              </Badge>
              <ShoppingCart className="h-5 w-5" />
            </Button>
            <Button
              variant="ghost"
              size="icon"
              onClick={handleClickFavoriteIcon}
            >
              <Heart className="h-5 w-5" />
            </Button>
            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <Button variant="ghost" className="flex items-center">
                  <User className="h-5 w-5" />
                  {currentUser && (
                    // name of the user
                    <span className="ml-2">{currentUser.username}</span>
                  )}
                </Button>
              </DropdownMenuTrigger>
              <DropdownMenuContent align="end">
                {currentUser ? (
                  <>
                    <DropdownMenuItem onClick={() => navigate("/profile")}>
                      Profile
                    </DropdownMenuItem>
                    <DropdownMenuItem onClick={handleLogout}>
                      Logout
                    </DropdownMenuItem>
                  </>
                ) : (
                  <>
                    <DropdownMenuItem onClick={handleLogin}>
                      Login
                    </DropdownMenuItem>
                    <DropdownMenuItem onClick={handleRegister}>
                      Register
                    </DropdownMenuItem>
                  </>
                )}
              </DropdownMenuContent>
            </DropdownMenu>
          </div>
          <div className="flex items-center sm:hidden">
            <MobileNavIcons />
            <Button
              variant="ghost"
              size="icon"
              onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
            >
              {isMobileMenuOpen ? (
                <X className="h-6 w-6" />
              ) : (
                <Menu className="h-6 w-6" />
              )}
            </Button>
          </div>
        </div>
      </div>

      <AnimatePresence>
        {isMobileMenuOpen && (
          <motion.div
            initial={{ x: "100%" }}
            animate={{ x: 0 }}
            exit={{ x: "100%" }}
            transition={{ duration: 0.3, ease: "easeInOut" }}
            className="sm:hidden bg-white px-10 pt-20 shadow-md fixed top-0 right-0 bottom-0 w-3/4 z-50"
          >
            <Button
              variant="ghost"
              className="absolute top-4 right-4"
              size="icon"
              onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
            >
              {isMobileMenuOpen ? (
                <X className="h-6 w-6" />
              ) : (
                <Menu className="h-6 w-6" />
              )}
            </Button>
            <div className="pt-2 pb-3 space-y-1">
              {MenuItems.map((item) => (
                <Link
                  key={item.name}
                  to={item.link}
                  onClick={handleLinkClick}
                  className="block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-red-500 transition-colors duration-300"
                >
                  {item.name}
                </Link>
              ))}
            </div>
            {/* login , profile dropdown  */}
            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <Button variant="outline">Profile/Login</Button>
              </DropdownMenuTrigger>
              <DropdownMenuContent align="end">
                {currentUser ? (
                  <>
                    <DropdownMenuItem
                      onClick={() => {
                        navigate("/profile");
                        setIsMobileMenuOpen(false);
                      }}
                    >
                      Profile
                    </DropdownMenuItem>
                    <DropdownMenuItem
                      onClick={() => {
                        handleLogout();
                        setIsMobileMenuOpen(false);
                      }}
                    >
                      Logout
                    </DropdownMenuItem>
                  </>
                ) : (
                  <>
                    <DropdownMenuItem
                      onClick={() => {
                        navigate("/auth/login");
                        setIsMobileMenuOpen(false);
                      }}
                    >
                      Login
                    </DropdownMenuItem>
                    <DropdownMenuItem
                      onClick={() => {
                        navigate("/auth/register");
                        setIsMobileMenuOpen(false);
                      }}
                    >
                      Register
                    </DropdownMenuItem>
                  </>
                )}
              </DropdownMenuContent>
            </DropdownMenu>
          </motion.div>
        )}
      </AnimatePresence>
    </nav>
  );
};

export default NavigationBar;
