import React from "react";
import { useNavigate } from "react-router-dom";
import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
} from "@/components/ui/card";
import { Button } from "@/components/ui/button";
import { Badge } from "@/components/ui/badge";
import { MapPin } from "lucide-react";

const VendorView = ({ vendor, isLatest }) => {
  const navigate = useNavigate();

  return (
    <Card
      className="w-full max-w-sm transition-all duration-300 ease-in-out transform hover:scale-105 hover:shadow-lg cursor-pointer"
      onClick={() => navigate(`/vendors/${vendor.slug}`)}
    >
      <CardHeader className="p-0">
        <img
          src={vendor.icon || "/api/placeholder/300/150"}
          alt={`${vendor.name} icon`}
          className="w-full h-40 object-cover rounded-t-lg"
        />
      </CardHeader>
      <CardContent className="p-4">
        <div className="flex justify-between items-start mb-2">
          <h3 className="text-lg font-semibold">{vendor.name}</h3>
          {isLatest && (
            <Badge variant="secondary" className="ml-2">
              New
            </Badge>
          )}
        </div>
        <div className="flex items-center text-sm text-gray-500 mb-2">
          <MapPin className="w-4 h-4 mr-1" />
          {vendor.location}
        </div>
        <p className="text-sm text-gray-600 line-clamp-3">
          {vendor.description}
        </p>
      </CardContent>
      <CardFooter>
        <Button
          variant="default"
          className="w-full"
          onClick={(e) => {
            e.stopPropagation();
            navigate(`/vendors/${vendor.slug}`);
          }}
        >
          View Details
        </Button>
      </CardFooter>
    </Card>
  );
};

export default VendorView;
