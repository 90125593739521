import { useEffect } from "react";

// Styles
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Checkbox,
  Stack,
  Typography,
} from "@mui/material";
import { ExpandMore } from "@mui/icons-material";
import theme from "../../theme";

// Data
import { IAccordionProps } from "@/types/accordion";
import { ISecondaryCategory, ITertiaryCategory } from "@/types/categories";
import { IParameter, ITertiaryParameter } from "@/types/parameters";

// Hooks
import { Link, useNavigate } from "react-router-dom";
import { useGetMappedCategories } from "../../hooks";
import { useSelector } from "react-redux";
import { RootState } from "../../app/store";
import { useCategories } from "../../hooks/useCategories";
import Loading from "../Loading";

const ProductTypeAccordion = ({ accordionStyles }: IAccordionProps) => {
  const navigate = useNavigate();

  const { categories, status } = useCategories();
  return (
    <Accordion
      sx={{ padding: "0", ...accordionStyles }}
      disableGutters
      defaultExpanded={true}
    >
      <AccordionSummary expandIcon={<ExpandMore />}>
        <Typography
          sx={{ fontSize: "12px", fontWeight: theme.fontWeight.semiBold }}
        >
          Product Categories
        </Typography>
      </AccordionSummary>
      <AccordionDetails sx={{ padding: "0" }}>
        {status === "loading" ? (
          <div>
            <Loading />
          </div>
        ) : (
          <div></div>
        )}
        {categories.map((secondaryCategory) => (
          <Accordion
            key={secondaryCategory.id}
            disableGutters
            sx={{
              boxShadow: "none",
              paddingLeft: "20px",
              "&.MuiAccordion-root::before": { opacity: "1 !important" },
            }}
          >
            <Stack direction="row" alignItems="center">
              {/* <Checkbox
                sx={{ height: "30px", width: "30px" }}
                checked={secondaryCategory?.isSelected}
                onClick={() => {}}
              /> */}

              <AccordionSummary
                // expandIcon={<ExpandMore />}
                sx={{
                  boxShadow: "none",
                  paddingLeft: "5px",
                  height: "30px",
                  width: "calc(100% - 30px)",
                }}
              >
                <Link to={`/products/${secondaryCategory.slug}`}>
                  <Typography
                    color={
                      // if the page (products/category) gis equal to the category.slug then color is primary
                      // else color is secondary
                      window.location.pathname ===
                      `/products/${secondaryCategory.slug}`
                        ? theme.palette.secondary.main
                        : "initial"
                    }
                    fontSize="12px"
                    fontWeight={theme.fontWeight.semiBold}
                  >
                    {secondaryCategory.name}
                  </Typography>
                </Link>
              </AccordionSummary>
            </Stack>
          </Accordion>
        ))}
      </AccordionDetails>
    </Accordion>
  );
};

export default ProductTypeAccordion;
