import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { IModifiedProduct, IProduct } from "@/types/product";
import axios from "axios";
import { IUserProduct } from "@/types/user";
import { IParameter } from "@/types/parameters";
import urls from "../../constants/urls";
import storagekeys from "../../constants/storagekeys";

interface ICartProducts {
  products: IModifiedProduct[] | null;
  totalCost: number;
}

interface IInitialState {
  products: IProduct[] | null;
  cartProducts: ICartProducts;
  productsByIds: IProduct[] | null;
  productsByCategory: IProduct[] | null;
  favoriteProducts: IProduct[] | null;
  loading: boolean;
}

const initialState: IInitialState = {
  products: null,
  productsByIds: null,
  productsByCategory: null,
  cartProducts: { products: null, totalCost: 0 },
  favoriteProducts: null,
  loading: false,
};

const productsSlice = createSlice({
  name: "products",
  initialState,
  reducers: {
    setCartProductsAndTotalCost(state, actions: PayloadAction<IUserProduct[]>) {
      // Implement your logic to set cart products and total cost
    },
    setFavoriteProducts(state, action: PayloadAction<string[] | undefined>) {
      // Implement your logic to set favorite products
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchAllProducts.pending, (state) => {
        state.loading = true;
      })
      .addCase(
        fetchAllProducts.fulfilled,
        (state, action: PayloadAction<IProduct[]>) => {
          state.loading = false;
          state.products = action.payload;
        }
      )
      .addCase(fetchProductsByCategories.pending, (state) => {
        state.loading = true;
      })
      .addCase(
        fetchProductsByCategories.fulfilled,
        (state, action: PayloadAction<IProduct[]>) => {
          state.loading = false;
          state.productsByCategory = action.payload;
        }
      );
  },
});

export const fetchAllProducts = createAsyncThunk(
  "products/fetchAllProducts",
  async () => {
    const response = await axios.get<IProduct[]>("/api/products");
    return response.data;
  }
);
export const fetchProductsByCategories = createAsyncThunk(
  "products/fetchProductsByCategories",
  async (
    {
      searchParameters,
      url,
    }: {
      searchParameters: {
        own?: boolean;
        vendor?: string | null;
        item_class?: string | null;
        category?: string | null;
        category_slug?: string | null;
        search?: string | null;
      };
      url?: string;
    },
    thunkAPI
  ) => {
    const storedUser = localStorage.getItem(storagekeys.user);
    const token = storedUser ? JSON.parse(storedUser).token : "";

    // Remove null and undefined values from searchParameters
    const filteredParams = Object.fromEntries(
      Object.entries(searchParameters).filter(
        ([_, value]) => value !== null && value !== undefined
      )
    );

    const queryString = Object.entries(filteredParams)
      .map(
        ([key, value]) =>
          `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
      )
      .join("&");

    const separator = url && url.includes("?") ? "&" : "?";
    const apiUrl = url
      ? `${url}${separator}${queryString}`
      : `${urls.items.all}?${queryString}`;

    const response = await axios.get<{
      results: IProduct[];
      count: number;
      next: string | null;
      previous: string | null;
    }>(apiUrl, {
      headers: {
        "Content-Type": "application/json",
      },
    }); // Replace with your actual API endpoint

    return response.data;
  }
);

export default productsSlice.reducer;
export const { setCartProductsAndTotalCost, setFavoriteProducts } =
  productsSlice.actions;
