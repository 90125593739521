import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  IconButton,
  Rating,
  Stack,
  Typography,
  useTheme,
  useMediaQuery,
  Skeleton,
} from "@mui/material";
import {
  ChevronRight,
  DescriptionOutlined,
  Star,
  Person2Outlined,
  CloseOutlined,
  ChevronLeft,
} from "@mui/icons-material";

import { ColorPalette, Counter, FavoriteButton } from "../../components";
import { ProductCareIcon, ProductMaterialsIcon } from "../../ui";
import VendorSection from "./vendor-view";
import ImageModal from "./modal";
import Thumbnails from "./thumbnails";
import FlexpayIntegration from "./flexpay";
import { RootState, useAppDispatch, useAppSelector } from "../../app/store";
import { fetchProduct } from "../../features/product/productSlice";
import {
  setUserLoading,
  setUserProductsInCart,
} from "../../features/user/userSlice";
import { addUserProductsInCart } from "../../helpers/addUserProductsInCart";

const ProductDetail = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const [quoteModalOpen, setQuoteModalOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);
  const [flexPayModalOpen, setFlexPayModalOpen] = useState(false);
  const [productQuantity, setProductQuantity] = useState(1);
  const [selectedTab, setSelectedTab] = useState<"description" | "reviews">(
    "reviews"
  );
  const [productReviews, setProductReviews] = useState([]);
  const [productReviewsStatus, setProductReviewsStatus] = useState<
    "loading" | "success" | "error"
  >("loading");

  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { currentUser, product, user } = useSelector(
    (state: RootState) => state
  );

  useEffect(() => {
    const id = searchParams.get("id");
    if (id) {
      dispatch(
        fetchProduct({
          productId: id,
          favoriteProductIds: user?.user?.favoriteProductIds,
        })
      );
    }
  }, [searchParams, user]);

  const handleGoBack = () => {
    navigate(-1);
  };

  const handleThumbnailClick = (index) => {
    setSelectedImageIndex(index);
    setIsModalOpen(true);
  };

  const handleClickAddToCartButton = () => {
    if (product.product && currentUser.currentUser) {
      dispatch(setUserLoading(true));
      addUserProductsInCart({
        product: product.product.id,
        quantity: productQuantity,
      })
        .then((result) => {
          dispatch(setUserProductsInCart(result));
          dispatch(setUserLoading(false));
        })
        .catch((error) => {
          console.log(error);
          dispatch(setUserLoading(false));
        });
    } else {
      navigate("/auth/login");
    }
  };

  const handleConfirmQuote = () => {
    window.open(`https://wa.me/${product?.product?.vendor?.phone}`, "_blank");
    setQuoteModalOpen(false);
  };

  const renderProductDescription = () => (
    <Stack alignItems="center" sx={{ width: { md: "50%" } }}>
      <DescriptionOutlined
        sx={{
          marginBottom: "49px",
          height: "67px",
          width: "52.2px",
          opacity: "0.1",
        }}
      />
      <Typography
        fontSize="16px"
        fontWeight={theme.typography.fontWeightSemiBold}
        textAlign="center"
        sx={{ marginBottom: "35px" }}
      >
        Details and product description
      </Typography>
      <Typography
        fontSize="14px"
        fontWeight={theme.typography.fontWeightRegular}
      >
        <div
          dangerouslySetInnerHTML={{ __html: product.product?.description }}
        />
      </Typography>
    </Stack>
  );

  const renderProductReviews = () => (
    <Stack
      rowGap="40px"
      sx={{
        columnGap: { xs: "60px", xl: "177px" },
        flexDirection: { md: "row" },
        alignItems: "center",
      }}
    >
      <Stack rowGap="40px" sx={{ width: { md: "50%" } }}>
        <Stack gap="40px" direction="row" flexWrap="wrap" alignItems="center">
          <Typography
            fontSize="16px"
            fontWeight={theme.typography.fontWeightRegular}
            textAlign="center"
          >
            {productReviews.length > 0 ? "Product Reviews" : "No reviews yet"}
          </Typography>
        </Stack>
      </Stack>
      <Stack rowGap="37px" sx={{ width: { md: "50%" } }}>
        {/* Render comments here */}
      </Stack>
    </Stack>
  );

  if (!product || !product.product) {
    return (
      <Box sx={{ padding: theme.spacing(4), paddingTop: theme.spacing(8) }}>
        <Skeleton variant="rectangular" width="100%" height={300} />
        <Skeleton variant="text" width="60%" height={40} sx={{ mt: 2 }} />
        <Skeleton variant="text" width="40%" height={30} sx={{ mt: 1 }} />
        <Stack direction="row" spacing={2} sx={{ mt: 2 }}>
          <Skeleton variant="rectangular" width={120} height={40} />
          <Skeleton variant="rectangular" width={120} height={40} />
        </Stack>
      </Box>
    );
  }

  return (
    <Stack
      sx={{
        minHeight: "500px",
        width: "100%",
        marginTop: { xs: "83px", md: "29px" },
        paddingLeft: {
          xs: theme.spacing(2),
          lg: theme.spacing(4),
          xl: theme.spacing(6),
        },
        paddingRight: {
          xs: theme.spacing(2),
          lg: theme.spacing(4),
          xl: theme.spacing(6),
        },
        paddingTop: theme.spacing(4),
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          padding: "16px",
          marginBottom: "16px",
        }}
      >
        <IconButton
          onClick={handleGoBack}
          sx={{
            mr: 1,
            color: "#fff",
            backgroundColor: "#f44336",
            borderRadius: "50%",
            padding: "8px",
            "&:hover": {
              backgroundColor: "#d32f2f",
            },
          }}
        >
          <ChevronLeft />
        </IconButton>
        <Box></Box>
      </Box>

      <Stack
        sx={{ flexDirection: { xs: "column", lg: "row" } }}
        columnGap="110px"
      >
        <Stack direction="row" justifyContent="center">
          <Box
            sx={{
              position: "relative",
              width: "max-content",
              maxWidth: { xs: "100%", sm: "601px", lg: "476px" },
              "& .product-detail-image": { maxWidth: "100%" },
            }}
          >
            <img
              src={product.product.icon}
              className="product-detail-image"
              alt={product.product.name}
            />
            <FavoriteButton
              sx={{ display: { xs: "flex", lg: "none" } }}
              productId={product.product.id}
              isFavorite={false}
            />
          </Box>
        </Stack>

        <Stack
          sx={{
            padding: "36px",
            paddingTop: { lg: "0px" },
            rowGap: "39px",
          }}
        >
          <Box>
            <Typography
              sx={{
                fontSize: { xs: "21px", sm: "25px", lg: "30px" },
                fontWeight: theme.typography.fontWeightLight,
              }}
            >
              {product.product.name}
            </Typography>
            {product.product.item_class !== "service" && (
              <Typography
                sx={{
                  fontSize: { xs: "21px", sm: "25px", lg: "30px" },
                  fontWeight: theme.typography.fontWeightRegular,
                }}
              >
                {Number(product.product.pricing_current).toLocaleString(
                  "en-KE",
                  {
                    style: "currency",
                    currency: "KES",
                  }
                )}
              </Typography>
            )}
          </Box>

          <Stack
            direction={{ xs: "column", sm: "row" }}
            gap="15px"
            flexWrap="wrap"
            alignItems="center"
          >
            <Stack
              direction={{ xs: "column", sm: "row" }}
              gap="15px"
              alignItems="center"
              sx={{ width: "100%" }}
            >
              <Button
                sx={{
                  backgroundColor: "#FBB03B",
                  borderRadius: "56px",
                  color: "#000000",
                  display: "flex",
                  alignItems: "center",
                  fontSize: "13px",
                  fontWeight: theme.typography.fontWeightSemiBold,
                  height: "49px",
                  width: { xs: "100%", sm: "189px" },
                  textAlign: "center",
                  "&:hover": {
                    backgroundColor: "#ffb53d",
                  },
                }}
                onClick={() => {
                  if (product?.product?.item_class !== "service") {
                    handleClickAddToCartButton();
                  } else {
                    setQuoteModalOpen(true);
                  }
                }}
              >
                {product.product.item_class !== "service"
                  ? "Add to cart"
                  : "Get a quote"}
              </Button>
              <Button
                sx={{
                  backgroundColor: "#fff",
                  borderRadius: "56px",
                  color: "#000000",
                  display: "flex",
                  alignItems: "center",
                  border: "2px solid #ffb53d",
                  fontSize: "13px",
                  fontWeight: theme.typography.fontWeightSemiBold,
                  height: "49px",
                  width: { xs: "100%", sm: "250px" },
                  textAlign: "center",
                  borderWidth: "2px",
                  "&:hover": {
                    borderColor: "#ffb53d",
                  },
                }}
                onClick={() => setFlexPayModalOpen(true)}
              >
                <img
                  src="/flexpaylogo.webp"
                  className="w-20 h-20 object-contain mr-3"
                  alt="Flexpay Logo"
                />
                Lipa pole pole
              </Button>
              <FavoriteButton
                position="static"
                sx={{ display: { xs: "none", lg: "flex" } }}
                productId={product.product.id}
                isFavorite={false}
              />
            </Stack>
            {product.product.images?.length > 1 && (
              <Thumbnails
                currentIndex={selectedImageIndex}
                images={product?.product?.images}
                onThumbnailClick={handleThumbnailClick}
              />
            )}
          </Stack>
        </Stack>
      </Stack>

      <Stack marginTop={3} marginBottom={3}>
        <VendorSection vendor={product.product.vendor} />
      </Stack>

      <Stack padding="36px" marginTop="33px" rowGap="39px" maxWidth="1500px">
        <Stack
          direction="row"
          sx={{
            columnGap: { xs: "10px", md: "40px" },
            justifyContent: { sm: "center" },
          }}
        >
          <Button
            sx={{
              height: "49px",
              width: "189px",
              border: `1px solid ${
                selectedTab === "description" ? "#FBB03B" : "#D8D8D8"
              }`,
              borderRadius: "49px",
              color: `${selectedTab === "description" ? "#000000" : "#424241"}`,
              fontSize: "13px",
              fontWeight: `${
                selectedTab === "description"
                  ? theme.typography.fontWeightSemiBold
                  : theme.typography.fontWeightRegular
              }`,
              textAlign: "center",
              "&:hover": {
                background: `${selectedTab === "description" && "#FBB03B"}`,
              },
            }}
            onClick={() => setSelectedTab("description")}
          >
            DESCRIPTION
          </Button>
          <Button
            sx={{
              height: "49px",
              width: "150px",
              border: `1px solid ${
                selectedTab === "reviews" ? "#FBB03B" : "#D8D8D8"
              }`,
              borderRadius: "49px",
              color: `${selectedTab === "reviews" ? "#000000" : "#D4D4D4"}`,
              fontSize: "13px",
              fontWeight: `${
                selectedTab === "reviews"
                  ? theme.typography.fontWeightSemiBold
                  : theme.typography.fontWeightRegular
              }`,
              textAlign: "center",
              "&:hover": {
                background: `${selectedTab === "reviews" && "#FBB03B"}`,
              },
            }}
            onClick={() => setSelectedTab("reviews")}
          >
            REVIEWS &nbsp; ({productReviews.length})
          </Button>
        </Stack>

        <Stack rowGap="20px">
          {selectedTab === "reviews" ? (
            <div className="product-reviews">
              {/* Add your AddReview component here */}
              {renderProductReviews()}
            </div>
          ) : (
            renderProductDescription()
          )}
        </Stack>
      </Stack>

      <ImageModal
        isModalOpen={isModalOpen}
        images={product?.product?.images}
        initialIndex={selectedImageIndex}
        onClose={() => setIsModalOpen(false)}
      />

      {flexPayModalOpen && (
        <div className="fixed inset-0 z-[300] bg-black bg-opacity-50 flex items-center justify-center">
          <FlexpayIntegration
            product={{
              name: product.product.name,
              price: product.product.pricing_current,
              description: product.product.description,
              image: product.product.icon,
              id: product.product.id,
            }}
            onClose={() => setFlexPayModalOpen(false)}
          />
        </div>
      )}

      {quoteModalOpen && (
        <div
          className="h-screen w-screen fixed top-0 left-0 bg-black bg-opacity-50 z-[300]"
          onClick={() => setQuoteModalOpen(false)}
        >
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: 400,
              bgcolor: "background.paper",
              border: "2px solid #000",
              boxShadow: 24,
              p: 4,
            }}
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <Typography id="modal-title" variant="h6" component="h2">
              Get a Quote
            </Typography>
            <Typography id="modal-description" sx={{ mt: 2 }}>
              You are about to be redirected to the vendor's WhatsApp for a
              quote. If you prefer to call them directly, their phone number is{" "}
              {product.product?.vendor?.phone}.
            </Typography>
            <Stack direction="row" spacing={2} sx={{ mt: 2 }}>
              <Button
                variant="contained"
                onClick={() => setQuoteModalOpen(false)}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                onClick={handleConfirmQuote}
                sx={{
                  backgroundColor: "#25D366",
                  "&:hover": {
                    backgroundColor: "#1DA851",
                  },
                }}
              >
                Visit Vendor WhatsApp
              </Button>
            </Stack>
          </Box>
        </div>
      )}
    </Stack>
  );
};

export default ProductDetail;
