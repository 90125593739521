import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState, useAppDispatch } from "../app/store";
import { fetchProductsByCategories } from "../features/products/productsSlice";
import { IProduct } from "../types/product";
import urls from "../constants/urls";
import storagekeys from "../constants/storagekeys";
import axios from "axios";
import { useSearchParams } from "react-router-dom";

interface UseProductsParams {
  own?: boolean;
  vendor?: string | null;
  itemClass?: string | null;
  category?: string | null;
  categorySlug?: string | null;
  search?: string | null;
}

const useProducts = (initialParams: UseProductsParams = {}) => {
  const dispatch = useAppDispatch();
  let [searchParams, setSearchParams] = useSearchParams();

  const UrlSearchQuery = searchParams.get("search") || "";
  const UrlPage = searchParams.get("page") || "";
  const UrlCategory = searchParams.get("category") || "";

  const own = initialParams.own;

  const vendor = initialParams.vendor || null;
  const itemClass = initialParams.itemClass || null;
  const category = initialParams.category || null;
  const categorySlug = initialParams.categorySlug || null;

  const [currentPage, setCurrentPage] = useState<number>(
    parseInt(UrlPage) || 1
  );

  const [products, setProducts] = useState<{
    count: number;
    next: string | null;
    previous: string | null;
    results: IProduct[];
  }>({} as any);
  const [productsStatus, setProductsStatus] = useState<
    "idle" | "loading" | "success" | "error"
  >("idle");

  const totalPages = products?.count ? Math.ceil(products.count / 10) : 0;
  const fetchNextPage = () => {
    if (currentPage < totalPages) {
      setSearchParams({ page: (currentPage + 1).toString() });
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };

  const fetchPreviousPage = () => {
    if (currentPage > 1) {
      setSearchParams({ page: (currentPage - 1).toString() });
      setCurrentPage((prevPage) => prevPage - 1);
    }
  };

  const fetchPage = (page: number) => {
    setCurrentPage(page);
  };

  const fetchProducts = async () => {
    setProductsStatus("loading");
    const storedUser = localStorage.getItem(storagekeys.user);
    const token = storedUser ? JSON.parse(storedUser).token : "";
    try {
      const productsResp = await axios.get(urls.items.all, {
        params: {
          own: initialParams.own,
          vendor: initialParams.vendor,
          item_class: initialParams.itemClass,
          category: initialParams.category,
          category_slug: initialParams.categorySlug,
          page: currentPage,
          search: initialParams.search,
        },
      });

      setProducts(productsResp.data);
      setProductsStatus("success");
    } catch (error) {
      console.error("Error fetching products:", error);
      setProductsStatus("error");
    }
  };

  console.log(initialParams);

  useEffect(() => {
    // debounce
    const timer = setTimeout(() => {
      fetchProducts();
    }, 500);
    return () => clearTimeout(timer);
  }, [
    currentPage,
    UrlSearchQuery,
    UrlCategory,
    own,
    vendor,
    itemClass,
    category,
    categorySlug,
  ]);

  return {
    products: products?.results,
    currentPage,
    fetchNextPage,
    fetchPreviousPage,
    fetchPage,
    totalPages,
    loading: productsStatus === "loading",
    productsStatus,
  };
};

export default useProducts;
