import axios from "axios";
import { SetStateAction } from "react";
import urls from "../constants/urls";
import {
  setCurrentUser,
  setCurrentUserLoading,
} from "../features/currentUser/currentUserSlice";
import { setUser, setUserLoading } from "../features/user/userSlice";
import { keys } from "lodash";
import storagekeys from "../constants/storagekeys";
export const signupWithEmail = async (
  values: {
    email: string;
    password: string;
    first_name: string;
    last_name: string;
  },
  states: {
    setLoading: React.Dispatch<SetStateAction<boolean>>;
    navigate: any;
    setErrorMessage: React.Dispatch<SetStateAction<string>>;
    dispatch: any;
  }
) => {
  const { setLoading, navigate, setErrorMessage, dispatch } = states;
  setLoading(true);
  dispatch(setCurrentUserLoading(true));
  let username;
  //   create username from first and last name

  username = values.first_name + values.last_name + Math.random() * 1000;
  // remove spaces and special characters
  username = username.replace(/\s/g, "");
  username = username.replace(/[^\w\s]/gi, "");

  try {
    const response = await axios.post(urls.auth.register, {
      email: values.email,
      username: username,
      password: values.password,
      first_name: values.first_name,
      last_name: values.last_name,
    });

    if (response.status === 201) {
      // Handle successful registration, you can navigate or set user state here.
      const token = response.data.token; // Assuming the API returns a token
      const user = response.data.user; // Assuming the API returns user data
      // Store the token in a secure place (e.g., in localStorage or cookies)
      localStorage.setItem(
        storagekeys.user,
        JSON.stringify({
          token,
          ...user,
        })
      );

      // Set user data in your application's state
      dispatch(setCurrentUser({ token, ...user }));
      dispatch(setCurrentUserLoading(false));
      dispatch(setUserLoading(false));
      dispatch(setUser({ token, ...user }));
      navigate("/");
    }
  } catch (error: any) {
    console.error(error.response.data);
    dispatch(setCurrentUserLoading(false));
    // Handle signup errors and set the error message
    if (error.response) {
      if (error.response.data["email"]) {
        setErrorMessage(error.response.data["email"]);
        alert(error.response.data["email"]);
        return;
      }
      if (error.response.data["username"]) {
        setErrorMessage(error.response.data["username"]);
        alert(error.response.data["username"]);
        return;
      }
      setErrorMessage(
        "Sorry there was an error signing up, please try again. If the problem persists, please contact support."
      );
      alert(
        "Sorry there was an error signing up, please try again. If the problem persists, please contact support."
      );
    } else {
      setErrorMessage(
        "Sorry there was an error signing up, please try again. If the problem persists, please contact support."
      );
      alert(
        "Sorry there was an error signing up, please try again. If the problem persists, please contact support."
      );
    }
  }
  setLoading(false);
};
