import { useEffect, useState } from "react";
import axios from "axios";
import urls from "../constants/urls";
import { useAppSelector } from "../app/store";
import Vendor from "../types/vendor";
export const useVendors = () => {
  const [vendors, setVendors] = useState<Vendor[]>([]);
  const [status, setStatus] = useState<
    "idle" | "loading" | "success" | "error"
  >("idle");

  const user = useAppSelector((state) => state.user);

  const fetchVendors = async () => {
    setStatus("loading");
    try {
      const response = await axios.get(urls.vendors.all, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      setVendors(response.data);
      setStatus("success");
    } catch (error) {
      console.error(error);
      setStatus("error");
    }
  };

  useEffect(() => {
    fetchVendors();
  }, [user]);

  return { vendors, status };
};
