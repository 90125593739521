import { useEffect } from "react";
import { RootState, useAppDispatch } from "../../app/store";
import {
  setCurrentUser,
  setCurrentUserLoading,
} from "../../features/currentUser/currentUserSlice";
import { setUser, setUserLoading } from "../../features/user/userSlice";
import { useSelector } from "react-redux";
import storagekeys from "../../constants/storagekeys";
import urls from "../../constants/urls";
import axios from "axios";

const Subscribe = () => {
  const dispatch = useAppDispatch();
  const currentUser = useSelector(
    (state: RootState) => state.currentUser.currentUser
  );

  useEffect(() => {
    const storedUser = localStorage.getItem(storagekeys.user);
    // console.log("storedToken", storedToken);
    const storedToken = storedUser ? JSON.parse(storedUser).token : null;
    console.log("storedToken", storedToken);

    if (storedToken) {
      // Send the token to your Django backend for authentication
      // You'll need to implement an API route on your backend to verify the token
      // and retrieve user data if the token is valid.
      axios
        .get(urls.auth.verify_token, {
          headers: {
            "Content-Type": "application/json",
            // Send the token in the "Authorization" header, e.g., as a Bearer token
            Authorization: `Token ${storedToken}`,
          },
        })
        .then((res) => {
          const data = res.data;
          if (data.user) {
            // The backend has verified the token, and user data is returned
            const user = data.user;
            dispatch(setCurrentUser({ ...user, token: storedToken }));
            dispatch(setCurrentUserLoading(false));
            // You can also set the user data in your application's state
            dispatch(setUser({ ...user, token: storedToken }));
            dispatch(setUserLoading(false));
          } else {
            // Token is not valid or user not found
            dispatch(setCurrentUser(null));
            dispatch(setCurrentUserLoading(false));
          }
        })
        .catch((error) => {
          // Handle errors, e.g., network issues
          console.error("Error verifying token:", error);
          dispatch(setCurrentUser(null));
          dispatch(setCurrentUserLoading(false));
        });
    } else {
      // No token found in local storage
      dispatch(setCurrentUser(null));
      dispatch(setCurrentUserLoading(false));
    }
  }, [dispatch]);

  return null;
};

export default Subscribe;
