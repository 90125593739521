import React from "react";
import { IconButton, Stack } from "@mui/material";
import { NavigateBefore, NavigateNext } from "@mui/icons-material";

const Thumbnails = ({ images, currentIndex, onThumbnailClick }) => {
  const handleThumbnailClick = (index) => {
    onThumbnailClick(index);
  };

  const handlePrevImage = () => {
    onThumbnailClick(currentIndex === 0 ? images.length - 1 : currentIndex - 1);
  };

  const handleNextImage = () => {
    onThumbnailClick(currentIndex === images.length - 1 ? 0 : currentIndex + 1);
  };

  return (
    <Stack
      direction="row"
      spacing={1}
      justifyContent="center"
      alignItems="center"
      maxWidth="100%"
    >
      <IconButton onClick={handlePrevImage}>
        <NavigateBefore />
      </IconButton>
      <Stack
        direction="row"
        spacing={1}
        justifyContent="center"
        alignItems="center"
        maxWidth="100%"
        overflow="scroll"
        // hide scrollbar
        sx={{
          "&::-webkit-scrollbar": {
            display: "none",
          },
          scrollbarWidth: "none",
          msOverflowStyle: "none",
        }}
      >
        {images.map((image, index) => {
          if (image.image == null) return null;
          return (
            <img
              key={index}
              src={image.image}
              alt={`Thumbnail ${index}`}
              style={{
                width: "100px",
                height: "60px",
                objectFit: "cover",
                cursor: "pointer",
                opacity: index === currentIndex ? 0.5 : 1,
              }}
              onClick={() => handleThumbnailClick(index)}
            />
          );
        })}
      </Stack>
      <IconButton onClick={handleNextImage}>
        <NavigateNext />
      </IconButton>
    </Stack>
  );
};

export default Thumbnails;
